import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { MesureImpactStateService } from "src/app/services/mesure-impact-state.service";
import { UserDataService } from 'src/app/services/user-data.service';
import { DataService } from 'src/app/services/data.service';
declare var dmi: any;

@Component({
  selector: 'app-mesure-impact',
  templateUrl: './mesure-impact.component.html',
  styleUrls: ['../../../css/mesureImpact/libMesureImpact.css', './mesure-impact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MesureImpactComponent implements OnInit {

  element: HTMLElement;
  state: number;

  constructor(
    private modalsService: ModalsService,
    private impactService: MesureImpactStateService,
    private userData: UserDataService,
    private ds: DataService,
  ) { }

  ngOnInit() {
    this.impactService.currentMessage.subscribe(message => this.state = message);

    this.element = document.getElementById("dmi-form-body");

    dmi.init(this.ds.projectId, this.ds.dmiEndpointUrl, this.ds.dmiFormNum, (res) => {

      if (res) {
        dmi.processForm(this.element, (res, log) => {

          if (res == 0) {
            // form launched, wait
          }
          if (res == 1) {
            this.state = 1;
            this.impactService.sendState(this.state);
            if(this.ds.uid === 'sncf')
              this.modalsService.open("game-end");
            else this.modalsService.open("gameover");
          }
          if (res == 2) {
            // this.userData.data.currentScore += this.ds.dmiBonusPoints;
            if(this.ds.uid === 'sncf')
              this.modalsService.open("game-end");
            else this.modalsService.open("gameover");
          }
          if (res == 3) {
            // form already finished, not supposed to happen 
          }
        });
      } else {
        console.error("The form failed to load.");
      }
    });
  }
}
