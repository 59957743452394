import { query, transition, trigger, group, useAnimation, animate, animation, animateChild, style, stagger, sequence } from '@angular/animations';
import { dsl } from './app.animations';




export const landingAnimation = [
	
  trigger('animations', [
		
		
    transition(':enter', group([
			 
			...dsl.sequence(['.dsl_fade2', '.dsl_fade3'], dsl.reset().fadeIn, 0.6, 0.0)
			
		])),
		
		transition(':leave', group([
			
			...dsl.sequence(['.dsl_fade2', '.dsl_fade3'], dsl.reset().fadeOut, 0.2, 0, true)
			
		])),
		
  ])
];

