import { Component, OnInit, HostBinding } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { endAnimation } from 'src/app/animations/home.animations';
import { DataService } from 'src/app/services/data.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-end-game-gift-page',
  templateUrl: './end-game-gift-page.component.html',
	styleUrls: ['./end-game-gift-page.component.scss'],
	animations: [endAnimation],
})
export class EndGameGiftPageComponent implements OnInit {

	@HostBinding('@animations') private animations;
  constructor(
		private modalService:ModalsService,
		private ds:DataService,
		private userData:UserDataService,
		private wordings: WordingsService,
	) { }

  ngOnInit() {
	}
	restart():void
	{
		this.userData.resetAll();
		this.userData.save();
		this.modalService.open('home');
	}
	

}
