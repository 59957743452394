import { CallbackType } from "./types";

export interface CallbackInfo{
	
	continue:boolean,
	type:CallbackType,
	questionLocKey:string,
	text:string,
	answerLocKeys:string[],
	actions:string[][],
	callback:boolean,
	background:string,
	talkers:string[],
	talkerName:string,
	nodeId:string,
	voiceFile?:string,		//analytics
	influence?:boolean[],
};


export function createMock(data:any = null):CallbackInfo{
	let output:CallbackInfo = {
		continue:true,
		type:null,
		questionLocKey:'',
		text:'',
		answerLocKeys:[],
		actions:[],
		callback: true,
		background:null,
		talkers:[],
		talkerName:null,
		nodeId:null,
	};
	output = {...output, ...data};
	return output;
};