import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
declare function getScoreData();

@Injectable({
  providedIn: 'root'
})
export class ApiService {
	
	constructor(
		private http:HttpClient,
	) { }
	
	
	private getRand():string
	{
		return ''+Math.round(Math.random() * 9999999);
	}
	
	
	public getChapter(index:number):Observable<any>
	{
		return this.http.get('assets/json/chapter-test.json');
		
	}
	
	getParams():Observable<any>
	{
		return this.http.get('assets/json/params.json');
	}
	
	getData(uid:string = null):Observable<any>
	{
		if(environment.mockApi){
			return this.http.get('assets/mock/getdata.mock.json');
		}
		if(!uid) uid = environment.defaultCompany;
		let body:any = {companyUid : uid};
		return this.http.post(environment.BASE_URL + '/getData', body);
	}
	
	
	
	
	
	getLeaderboard(uid:string):Observable<any>
	{
		if(!uid) uid = environment.defaultCompany;
		let headers:any = {};
		headers['Content-Type'] = 'application/x-www-form-urlencoded';
		
		let body:any = {companyUid : uid};
		return this.http.post(environment.BASE_URL + '/getLeaderboard', body);
		
	}
	
	
	
	getIntermediateLeaderboard(uid:string, score:number, type:number):Observable<any>
	{
		if(!uid) uid = environment.defaultCompany;
		let headers:any = {};
		headers['Content-Type'] = 'application/x-www-form-urlencoded';
		
		let body:any = {
			companyUid : uid,
			score : score,
			scoreType : type,
		};
		return this.http.post(environment.BASE_URL + '/getIntermediateLeaderboard', body);
		
	}
	
	
	
	saveScore(nickname:string, uid:string, additionalData1: string, additionalData2: string):Observable<any>
	{
		//scores:{chap1, chap2, chap3, end}
		let data = getScoreData();
		console.log(data);
		data.companyUid = uid;
		if(!uid) data.companyUid = environment.defaultCompany;
		data.nickname = nickname;
		data.additional_data_1 = additionalData1;
		data.additional_data_2 = additionalData2;
		return this.http.post(environment.BASE_URL + '/saveScore', data);
	}
	
	
	login(login:string, password:string):Observable<any>
	{
		let body:any = {
			login,
			password,
		};
		return this.http.post(environment.BASE_URL + '/login', body);

	}
	
	registerUser(uid: string = environment.defaultCompany)
	{
		if(!uid) uid = environment.defaultCompany;
		let body:any = {companyUid : uid};
		return this.http.post(environment.BASE_URL + '/registerUser', body);
	}
	
}
