import { UserDataService } from 'src/app/services/user-data.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

	constructor(
		private modalService:ModalsService,
		private userData:UserDataService,
		private ds:DataService,
		private api:ApiService,
	) { }
	
	//restart_game / restart_chapter
	restart(mode:string):void
	{
		//todo : if intro / outro, different...
		//mais pas sur qu'on laisse le btn menu accessible
		
		let indexScenario:number = this.userData.data.indexScenario;
		
		//intro/outro
		let dummy:boolean = false;
		if((indexScenario == 0 || indexScenario == 4) && dummy){
			// this.mod
		}
		//chapters
		else{
			
			if(mode == 'restart_game') {
				if(location.href.match(/\?test/)) {
					location.reload();
				} else {

					this.userData.resetAll();
				}
			}
			else if(mode == 'restart_chapter') this.userData.resetChapter();
			this.userData.save();
			
			if(mode == 'restart_game'){
				this.modalService.open('home');
				console.log('open home');
			}
			else if(mode == 'restart_chapter') this.modalService.open('chapter-start');
		}
	}
	
	startGame():void
	{
		let indexScenario:number = this.userData.data.indexScenario;
		
		if(indexScenario == 0){
			this.modalService.open('game');
		}
		else if(indexScenario >= 4){
			console.log(this.ds.uid + " === " + 'Thales-LAS-France')
			if(this.ds.uid === 'Thales-LAS-France')
				this.modalService.open('gameover');
			else
				this.modalService.open('quiz');
		}
		else{
			this.modalService.open('chapter-start');
		}
	}

	endGame():void
	{
		let indexScenario:number = this.userData.data.indexScenario;
		
		//never equal 0 here, incremented before
		if(indexScenario == 1){
			
			this.ds.opentutoMenu = false;
			this.modalService.open('tuto', 'modal')
			.then(() => {
				this.modalService.open('chapter-start');
			});
		}
		else if(indexScenario == 5){
			if(this.ds.uid === 'Thales-LAS-France')
				this.modalService.open('gameover');
			else
				this.modalService.open('quiz');
		}
		else{
			let score:number = this.userData.data.currentScore;
			let scoreType:number = indexScenario - 1;
			
			this.api.getIntermediateLeaderboard(this.ds.uid, score, scoreType)
			.subscribe(resp => {
				//leaderboard_global
				let playerRank:string = resp.data.playerRank;
				this.ds.leaderboard_endchapter = this.ds.getLeaderboardHero(resp.data.leaderboard, playerRank);
				console.log(this.ds.leaderboard_endchapter);
				
				let indexHero:number;
				this.ds.leaderboard_endchapter.forEach((rank, index) => {
					if(rank.rank == playerRank) indexHero = index;
				});
				
				//pour l'instant : afficher 3 premiere lignes
				let start_index = Math.max(0, indexHero - 1);
				this.ds.leaderboard_endchapter = this.ds.leaderboard_endchapter.slice(start_index, start_index + 3);
				if(this.ds.leaderboard_endchapter[Math.min(indexHero, 1)]){
					this.ds.leaderboard_endchapter[Math.min(indexHero, 1)].isHero = true;
				}
				console.log(this.ds.leaderboard_endchapter);
				
				this.modalService.open('chapter-end');
				
			});
		}
	}
	
	afterChapterEnd(success:boolean = true):void
	{
		let indexScenario:number = this.userData.data.indexScenario;
		
		if(indexScenario == 4){
			this.modalService.open('game');
		}
		else{
			this.modalService.open('chapter-start');
		}
	}
	
	afterTuto():void
	{
		this.modalService.close('modal');
		
	}
}
