import { UserData } from './../../model/userdata.interface';
import { NavigationService } from './../../services/navigation.service';
import { DataService } from './../../services/data.service';
import { environment } from './../../../environments/environment';
import { GameService } from './../../services/game.service';
import { GameNode } from '../../model/gamenode.interface';
import { Component, HostListener, OnInit, ViewChildren, ElementRef, QueryList, ViewChild, Renderer2, ApplicationRef } from '@angular/core';
import { CallbackInfo } from 'src/app/model/callbackinfo.interface';
import { CallbackType } from 'src/app/model/types';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import {TweenMax, Elastic} from "gsap";
import { TimeoutService } from 'src/app/shared/time/timeout.service';
import { fxAnimations } from 'src/app/animations/fx.animations';
import { InfoScrollService } from 'src/app/shared/ui/info-scroll.service';
import * as $ from 'jquery';
import { UserDataService } from 'src/app/services/user-data.service';
import { GoogleAnalyticsService } from 'src/app/shared/api/google-analytics.service';
import { SoundPlayerService } from 'src/app/shared/sound/sound-player.service';
import { PlatformService } from 'src/app/shared/utils/platform.service';
import {NavigationService as NavigationServiceShared} from './../../shared/navigation/navigation.service';
import { ScrollBlockComponent } from 'src/app/shared/ui/scroll-block/scroll-block.component';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { Easing } from 'src/app/shared/animation/animation.interface';
import { AppAnimateService } from 'src/app/services/app.animate.service';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-page-game',
  templateUrl: './page-game.component.html',
	styleUrls: ['./page-game.component.scss'],
	animations: [fxAnimations],
})
export class PageGameComponent implements OnInit {
	
	
	@ViewChild('footer') footer:ElementRef;
	@ViewChild('answers') answers:ElementRef;
	@ViewChild('talkerContent') talkerContentComponent:ScrollBlockComponent;
	@ViewChild('bg') bgElmt:ElementRef;
	@ViewChild('talkers') talkersElmt:ElementRef;
	
	
	previousTalkers:string[] = [null, null];
	talkersTab:string[];
	talker1:string;
	talker2:string;
	
	styleBG:any = {'background-image' : '',};
	node:GameNode;
	level:number;
	infoDialog:CallbackInfo;
	dataActions:any = {};
	blockDisplay:string;
	displayBulle:boolean;
	displayBulle1:boolean;
	displayBulle2:boolean;
	countDebug:number;
	infoScroll:InfoScrollService;
	indexScenario:number;
	isChapter:boolean;
	indexProgression:number
	changeBG:boolean;
	bg:string;
	showHeader:boolean;
	talkerName:string;
	talkerColor:string;
	heroName:string;
	heroColor:string;
	contentBulleLocKey:string;
	textDidascalie:string;
	initJauge:boolean;
	styleFooter:any;
	indexDebug:number;
	showBtnNext:boolean;
	
	jaugeAdisplay:number;
	jaugeBdisplay:number;
	
	type:string;
	
	label_jauge1:string;
	label_jauge2:string;
	styleScroll:any;
	styleScrollGrad:any;
	
	changeColorA:string;
	changeColorB:string;
	displayEffect:boolean = false;
	effectJaugeA:number;
	effectJaugeB:number;
	
	deltaChangeA:number;
	deltaChangeB:number;
	

  constructor(
		private gameService:GameService,
		private ds:DataService,
		private modalService:ModalsService,
		private renderer:Renderer2,
		private timeout:TimeoutService,
		private app:ApplicationRef,
		private userData:UserDataService,
		private navigation:NavigationService,
		private ga:GoogleAnalyticsService,
		private soundPlayer:SoundPlayerService,
		private platform:PlatformService,
		private navShared:NavigationServiceShared,
		private routerEvent:RouterEventService,
		private storage:StorageService,
		private animate:TweenAnimateService,
		private appAnimate:AppAnimateService,
		private wordings: WordingsService,
		
	) { }

  ngOnInit() {	
		
		this.jaugeAdisplay = -1;
		this.jaugeBdisplay = -1;
		
		this.countDebug = 0;
		this.indexScenario = this.userData.data.indexScenario;
		
		
		
		this.label_jauge1 = 'ui.game.jauge' + (this.indexScenario*2 - 1);
		this.label_jauge2 = 'ui.game.jauge' + (this.indexScenario*2 - 0);
		
		
		this.indexProgression = this.indexScenario;
		this.bg = this.userData.data.bg;
		
		this.changeBG = false;
		if(this.bg) this.changeBG = true;
		
		this.initJauge = false;
		this.indexDebug = 0;
		
		//set type and isChapter
		this.type = [0, 4].indexOf(this.indexScenario) > -1 ? 'intro' : 'chapter';
		this.isChapter = (this.type == 'chapter');
		
		
		this.displayBulle = false;
		this.displayBulle1 = false;
		this.displayBulle2 = false;
		this.showHeader = false;
		
		this.gameService.callback = this.callback.bind(this);
		
		
		let nodeId:string = this.userData.data.nodeId || 'n0';
		
		let dataActions:any = this.userData.data.dataActions || null;
		
		
		this.gameService.init(this.ds.scenarios[this.indexScenario].graph, nodeId, dataActions, this.isChapter);
		
		if(this.platform.isFirefox()){
			this.styleScroll = { 'overflow-y' : 'hidden'};
		}
		
		
		let heroKey:string;
		if(this.isChapter){
			
			if(this.indexScenario == 1) heroKey = 'jim';
			else if(this.indexScenario == 2) heroKey = 'bernard';
			else if(this.indexScenario == 3) heroKey = 'alice';
			
			this.heroColor = this.wordings.translate('ui.color.'+heroKey);
			this.heroName = this.wordings.translate('ui.name.'+heroKey);
		}
		
		this.styleScrollGrad = {
			['-webkit-mask-image'] : '-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(0, 0, 0)), color-stop(0.88, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))'
		};
		/* 
		if(environment.autoClick){
			this.userData.data.indexScenario = 2;
			this.userData.data.currentScore = 25;
			this.navigation.endGame();
		}
		 */
		
	}
	
	
	ngAfterInit(){
		
		
	}
	
	
	
	//callback async ; needs to recall gameService.followNodes later
	
	callback(info:CallbackInfo, node:GameNode, level:number):void
	{
		console.log('::callback '+(info && info.type));
		
		this.node = node;
		this.level = level;
		
		
		this.previousTalkers = [];
		if(this.infoDialog && this.infoDialog.talkers && this.infoDialog.talkers.length >= 1) this.previousTalkers[0] = this.infoDialog.talkers[0];
		if(this.infoDialog && this.infoDialog.talkers && this.infoDialog.talkers.length >= 2) this.previousTalkers[1] = this.infoDialog.talkers[1];
		
		
		this.infoDialog = info;
		console.log(info);
		this.dataActions = this.gameService.dataActions;
		
		//keep lower than 100
		
		
		
		
		//scroll infos
		
		if(this.talkerContentComponent){
			setTimeout(() => {
				this.talkerContentComponent.setScrollTop(0);
			}, 0);
		}
		
		
		
		
		
		//new dialog (generic)
		
		if(info.type == CallbackType.Dialog || info.type == CallbackType.Didascalie){
			
			this.displayEffect = false;
			this.ga.emitEvent('scenario', 'replique', info.voiceFile);
			
			
			//answers check influence
			this.dataActions;
			
			this.infoDialog.influence = [];
			this.infoDialog.actions.forEach((actions:string[]) => {
				let infoJaugeA = this.getEffectJauge(actions, 'A');
				let infoJaugeB = this.getEffectJauge(actions, 'B');
				this.infoDialog.influence.push((infoJaugeA.effect != 0 || infoJaugeB.effect != 0));
			});
			
			
			console.log('nodeID : '+this.infoDialog.nodeId);
			let idprogress:string = this.ds.params.score['id_node_progress' + this.indexScenario];
			
			if(this.infoDialog.nodeId == idprogress){
				this.userData.data.indexProgression++;
				
				let is:number = this.indexScenario;
				if(is == 1) this.userData.data.indexProgression = 1 + 1;
				else if(is == 2) this.userData.data.indexProgression = 3 + 1;
				else if(is == 3) this.userData.data.indexProgression = 5 + 1;
			}
			
			
			
			
			//test gameover
			let indexChapter:number = this.userData.data.indexScenario - 1;
			if(indexChapter >= 0){
				
				let indexJaugeA:number = indexChapter * 2 + 0;
				let indexJaugeB:number = indexChapter * 2 + 1;
				
				
				let prevJaugeA:number = this.ds.jaugeA;
				let prevJaugeB:number = this.ds.jaugeB;
				
				let dummy:boolean = false;
				if(dummy){
				}
				else{
					
					for(let k in this.dataActions){
						if(this.dataActions[k] > 100) this.dataActions = 100;
					}
					
					this.ds.jaugeA = this.dataActions['jauge' + (indexJaugeA + 1)];
					this.ds.jaugeB = this.dataActions['jauge' + (indexJaugeB + 1)];
					
				}
				if(this.jaugeAdisplay == -1) this.jaugeAdisplay = this.ds.jaugeA;
				if(this.jaugeBdisplay == -1) this.jaugeBdisplay = this.ds.jaugeB;
				//todo : display this in header
				
				if(this.ds.jaugeA <= 0 || this.ds.jaugeB <= 0){
					
					if(this.ds.jaugeA < 0) this.ds.jaugeA = 0;
					if(this.ds.jaugeB < 0) this.ds.jaugeB = 0;
					
					this.ds.scoreChapter = this.calculateScoreChapter();
					
					setTimeout(() => {
						
						this.navShared.open('gameover2', null, {
							params: {
								jaugeA_start: prevJaugeA,
								jaugeA_end: this.ds.jaugeA,
								jaugeB_start: prevJaugeB,
								jaugeB_end: this.ds.jaugeB,
							}
						});
					}, 1600);
					return;
				}
				
				if(this.initJauge && this.indexScenario <= 4){
					//old anim jauge
				}
				else this.initJauge = true;
				
			}
			
			
			
			
			//google analytics
			if(this.storage.getBoolean('tracking')) this.ga.emitEvent('alive', 'alive');
			
			
			
			//anim bg + persos
			
			let time:number = 300;
			if(this.isChapter) this.showHeader = true;
			
			let addTime:boolean = false;
			if(this.changeBG){
				
				//bg
				if(this.bgElmt){
					
					if(this.styleBG['background-image']){
						TweenMax.to(this.bgElmt.nativeElement, this.getTime(1.0), {opacity:0, delay:(time/1000 + 0.0)});
						this.timeout.setTimeout(() => {
							this.styleBG['background-image'] = 'url("assets/dynamic/decors/' + this.bg + '.png")';
						}, time + 1000);
					}
					else{
						this.styleBG['background-image'] = 'url("assets/dynamic/decors/' + this.bg + '.png")';
					}
				
					this.timeout.setTimeout(() => {
						
						this.renderer.setStyle(this.bgElmt.nativeElement, 'opacity', 0);
						this.renderer.setStyle(this.bgElmt.nativeElement, 'left', '-100px');
						
						TweenMax.to(this.bgElmt.nativeElement, this.getTime(1), {opacity:1, delay:0.0});
						TweenMax.to(this.bgElmt.nativeElement, this.getTime(1.5), {left:'0px', delay:0.2});
					}, time + 1300);
				}
				
				
				//persos
				
				if(this.talkersElmt){
					this.renderer.setStyle(this.talkersElmt.nativeElement, 'opacity', 1);
					this.renderer.setStyle(this.talkersElmt.nativeElement, 'left', '1300px');
					// TweenMax.to(this.talkersElmt.nativeElement, 1, {opacity:1, delay:1.3});
					TweenMax.to(this.talkersElmt.nativeElement, this.getTime(1.5), {left:'0px', delay:time/1000 + 1.5});
				}
				
				
				addTime = true;
				this.changeBG = false;
			}
			
			
			
			//fade perso
			
			
			
			
			if(this.previousTalkers[0] != this.infoDialog.talkers[0]){
				
				let time2:number;
				
				if(this.previousTalkers[0]){
					this.animate.reset().fadeOut('.talker1', time/1000 + 0, { timeOut: 0.15});
					this.animate.fadeIn('.talker1', time/1000 + 0.3, { timeIn: 0.3});
					time2 = 150;
				}
				else{
					setTimeout(() => {
						this.animate.reset().slideIn('.talker1', 0, { 
							timeIn: 1.0,
							dir: 'left',
							distanceIn: 600,
							useMargin: true,
							fade:false,
							easeIn: Easing.EASE_OUT,
						});
					}, time + 400);
					time2 = 400;
				}
				
				setTimeout(() => {
					this.talkersTab = [this.infoDialog.talkers[0], this.infoDialog.talkers[1]];
				}, time + time2);
				
			}
			if(this.previousTalkers[1] != this.infoDialog.talkers[1]){
				this.animate.reset().fadeOut('.talker2', time/1000 + 0, { timeOut: 0.2});
				this.animate.fadeIn('.talker2', time/1000 + 0.3, { timeIn: 0.3});
			}
			
			
			
			if(addTime) time += 2800;
			
			var timebulle:number = time + 400;
			var timefooter:number = time + 800;
			
			if(info.type == CallbackType.Didascalie){
				timefooter = time + 400;
			}
			
			
			
			//anim bulle
			
			this.displayBulle = false;
			this.displayBulle1 = false;
			this.displayBulle2 = false;
			this.timeout.setTimeout(() => {
				
				this.displayBulle = (info.questionLocKey != null);
				this.displayBulle1 = (info.talkers[0] == 'alice_neutre');
				this.displayBulle2 = (info.talkers[0] == 'jim_neutre');
				
				this.timeout.setTimeout(() => {
					
					this.talkerColor = this.wordings.translate('ui.color.'+this.infoDialog.talkerName);
					this.talkerName = this.infoDialog.talkerName;
					this.talkerName = this.wordings.translate('ui.name.'+this.talkerName);
					
					this.contentBulleLocKey = this.infoDialog.questionLocKey;
					this.timeout.setTimeout(() => {
						this.talkerContentComponent.updateHasScroll();
					}, 1);
					
					// this.contentBulle = 'AAAAAAAAAAAAAAAAAAAA M M AJZ RLKASJ FOAZIJUR AOIZSFJ LAKSJF ALKZSEJR AOISDF OQSLKJF LQSKDFJ AZOIERUF LQDSKFJ QSDLIKFJ SQDFJKq smdflkqjs mlfkjsmlfkj';
				}, this.displayBulle ? 0 : 200);
				
				
			}, timebulle);
			
			
			
			
			//anim footer
			
			this.renderer.setStyle(this.footer.nativeElement, 'opacity', 0);
			if(this.footer){
				this.timeout.setTimeout(() => {
					TweenMax.to(this.footer.nativeElement, this.getTime(0.2), { opacity:1});
				}, timefooter);
			}
			
			
			//save data
			this.userData.data.dataActions = this.dataActions;
			this.userData.data.nodeId = info.nodeId;
			this.userData.data.bg = this.bg;
			this.userData.save();
			
		}
		
		
		
		
		//type dialog
		
		if(info.type == CallbackType.Dialog){
			
			console.log('Question : '+info.questionLocKey);
			console.log('Answers :::');
			
			let index:number = 0;
			for(let k in info.answerLocKeys){
				let answer = info.answerLocKeys[k];
				console.log(index + '- '+answer);
				index++;
			}
			
			this.blockDisplay = CallbackType.Dialog;
			
			
			this.userData.addLog(info.talkerName, info.questionLocKey);
			this.userData.save();
			
		}
		
		//didascalie
		else if(info.type == CallbackType.Didascalie){
			
			this.blockDisplay = CallbackType.Didascalie;
			this.textDidascalie = info.text;
			//[Didascalie] |... Jim
			
			this.userData.addLog('', info.text, true);
			
			if(info.questionLocKey){
				this.userData.addLog(info.talkerName, info.questionLocKey);
				this.userData.save();
				
				
			}
			
			
		}
		
		else if(info.type == CallbackType.Ellipse){
			
			this.modalService.open('elipse', 'modal');
			
			this.ds.textDidascalie = info.text;
			
			let time:number = 1.3 + info.text.length * 0.1 + 1.3;
			if(environment.debug) time = 0.6;
			
			this.timeout.setTimeout(() => {
				this.modalService.close('modal');
			}, time * 1000);
			
			
			let time2:number = time * 1000 + 500;
			if(!this.isChapter) time2 -= 1000;
			
			this.timeout.setTimeout(() => {
				this.follow();
			}, time2);
			
		}
		
		//background
		else if(info.type == CallbackType.Background){
			
			this.changeBG = true;
			this.bg = info.background;
			
			
		}
		
		//end
		else if(info.type == CallbackType.End){
			
			this.onEnd();
			
		}
		
		
		this.showBtnNext = (this.blockDisplay == 'Didascalie' || (this.blockDisplay=="Dialog" && this.infoDialog.answerLocKeys.length == 1));
		
		
		/* 
		if(!info.continue && environment.autoClick && this.countDebug < 7){
			this.countDebug++;
			this.gameService.followNodes(node, level, 0);
		}
		 */
		
	}
	
	
	
	
	
	calculateScoreChapter():number
	{
		let indexChapter:number = this.userData.data.indexScenario - 1;
		
		let indexJaugeA:number = indexChapter * 2 + 0;
		let indexJaugeB:number = indexChapter * 2 + 1;
		
		this.ds.jaugeA = this.dataActions['jauge' + (indexJaugeA + 1)];
		this.ds.jaugeB = this.dataActions['jauge' + (indexJaugeB + 1)];
		
		let jaugeA:number = this.ds.jaugeA;
		if(jaugeA < 0) jaugeA = 0;
		let jaugeB:number = this.ds.jaugeB;
		if(jaugeB < 0) jaugeB = 0;
		
		let factors:any = this.ds.params.score.jauges_factor;
		
		let factor1:number = factors[indexJaugeA];
		let factor2:number = factors[indexJaugeB];
		
		let output:number = Math.round(factor1 * jaugeA + factor2 * jaugeB);
		
		let ptsGameover:number = +this.ds.params.score.pts_per_gameover;
		output -= ptsGameover * this.userData.data.ngGameover;
		if(output < 1) output = 1;
		
		return output;
	}
	
	
	
	
	onEnd():void
	{
		this.ds.scoreChapter = this.calculateScoreChapter();
		// if(environment.debug) this.ds.scoreChapter = 265;
		
		console.log('this.ds.scoreChapter :'+this.ds.scoreChapter);
		
		if(!isNaN(this.ds.scoreChapter)){
			this.userData.data.currentScore += this.ds.scoreChapter;
			this.userData.saveScore(this.userData.data.currentScore, this.userData.data.indexScenario);
		}
		
		this.userData.data.indexScenario++;
		
		let is:number = this.userData.data.indexScenario;
		if(is == 4) this.userData.data.indexProgression = 7;
		
		this.userData.resetChapter();
		this.userData.save();
		
		this.timeout.setTimeout(() => {
			this.navigation.endGame();
		}, 500);
		
	}
	
	
	
	@HostListener('click', ['$event'])
  clickEvent(event: KeyboardEvent) {
		
		// console.log('click game');
		if(this.routerEvent.isRouteActive('history')){
			this.navShared.close('modal_game');
		}
		
  }
	
	
	
	@HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
		
		let selectedIndex:number = event.keyCode - 96;
		if(selectedIndex >= 0 && selectedIndex <= 4){
			
			this.follow(selectedIndex);
		}
  }
	
	
	
	clickAnswer(index:number):void
	{
		let selectedIndex = index;
		
		let answerElmts:any[] = this.answers.nativeElement.children;
		let selectedElmt = answerElmts[index];
		
		let labelGA:string = this.infoDialog.voiceFile;
		this.ga.emitEvent('scenario', 'reponse', labelGA);
		
		//set selected item yellow
		this.renderer.addClass(selectedElmt, 'col_tonic');
		
		
		this.timeout.setTimeout(() => {
			this.displayBulle = false;
		}, 0);
		
		
		/* 
		if(environment.debug){
			
			let actionsDebug:string[][] = [
				['$jauge1+=7'],
				['$jauge1+=8'],
				['$jauge1+=9'],
				['$jauge2-=6'],
				
			];
			this.infoDialog.actions[selectedIndex] = actionsDebug[this.indexDebug];
			this.indexDebug++;
		}
		 */
		
		
		let actions:string[] = this.infoDialog.actions[selectedIndex];
		
		let infoJaugeA = this.getEffectJauge(actions, 'A');
		let infoJaugeB = this.getEffectJauge(actions, 'B');
		
		if(infoJaugeA.value > 100) infoJaugeA.value = 100;
		if(infoJaugeB.value > 100) infoJaugeB.value = 100;
		
		this.effectJaugeA = infoJaugeA.effect;
		this.effectJaugeB = infoJaugeB.effect;
		
		
		let animEffect:boolean = this.effectJaugeA != 0 || this.effectJaugeB != 0;
		
		
		let addDelay:number = 0;
		if(animEffect) addDelay = 1200;
		// if(environment	.debug) addDelay += 99999;
		
		if(!environment.skipTimeout){	
			this.styleFooter = { 'pointer-events' : 'none'};
			setTimeout(() => {
				this.styleFooter = { 'pointer-events' : 'initial'};
			}, 800 + addDelay);
		}
		
		
		//make other item disepear
		for(let i=0;i<answerElmts.length;i++){
			
			if(i != selectedIndex){
				TweenMax.to(answerElmts[i], this.getTime(0.3), { opacity:0});
			}
			else{
				if(animEffect){ 
					let jaugeElmt = answerElmts[i].querySelector('.jauge_effects');
					if(jaugeElmt) jaugeElmt.style.visibility = 'visible';
				}
				let sepElmt = answerElmts[i].querySelector('.answer_sep');
				if(sepElmt) sepElmt.style.visibility = 'hidden';
			}
		}
		
		//make the effect appear
		if(animEffect){
			this.displayEffect = true;
		}
		
		this.timeout.setTimeout(() => {
			TweenMax.to(this.footer.nativeElement, this.getTime(0.2), { opacity:0});
		}, 0 + addDelay);
		
		
		
		
		if(this.indexScenario <= 4){
			
			let blockA:boolean = (infoJaugeA.value > 0 && this.ds.jaugeA >= 100);
			let blockB:boolean = (infoJaugeB.value > 0 && this.ds.jaugeB >= 100);
			
			if(infoJaugeA.value != 0 && !blockA) this.animateJauge(infoJaugeA.value, 'A');
			if(infoJaugeB.value != 0 && !blockB) this.animateJauge(infoJaugeB.value, 'B');
			
		}
		
		
		
		//follow nodes
		this.timeout.setTimeout(() => {
			
			this.follow(selectedIndex);
		}, 200 + addDelay);
		
		
	}
	
	
	
	
	private getValueJauge(actions:string[], type:string):number
	{
		let len:number = actions.length;
		for(let i=0;i<len;i++){
			let action = actions[i];
			let num:number = parseInt(action.substr(8));
			if((type == 'A' && num % 2 == 1) ||
				(type == 'B' && num % 2 == 0)){
				let operation:string = action.substr(7, 2);
				if(operation == '+=') return 1;
				else if(operation == '-=') return -1;
			}
		}
		return 0;
	}
	
	private getEffectJauge(actions:string[], type:string):{effect:number, value:number}
	{
		//$jauge1+=x
		let len:number = actions.length;
		for(let i=0;i<len;i++){
			let action = actions[i];
			
			if(action.substr(0, 6) == '$jauge'){
				
				let num:number = parseInt(action.substr(6, 1));
				if((type == 'A' && num % 2 == 1) ||
					(type == 'B' && num % 2 == 0)){
					let operation:string = action.substr(7, 2);
					let value:number = +action.substr(9);
					
					let valueJauge = this.dataActions['jauge'+num];
					valueJauge = 99;	//cancel
					
					if(operation == '+=' && valueJauge < 100) return {effect:1, value: value};
					else if(operation == '-=' && valueJauge > 0) return {effect:-1, value: -value};
				}
			}
			
		}
		return {effect:0, value:0};
	}
	
	
	clickNext():void
	{
		this.infoDialog;
		
		//chapter
		if (this.isChapter || (this.infoDialog.answerLocKeys && this.infoDialog.answerLocKeys.length > 0)) {
			if(this.blockDisplay != CallbackType.Dialog) {
				this.blockDisplay = CallbackType.Dialog;
				this.showBtnNext = (this.blockDisplay == 'Didascalie' || (this.blockDisplay=="Dialog" && this.infoDialog.answerLocKeys.length == 1));
			}
			//cas du 1 réponse
			else{
				this.clickAnswer(0);
			}
		}
		//intro / outro
		else{
			this.follow();
		}
		
		
		if(!environment.skipTimeout){	
			this.styleFooter = { 'pointer-events' : 'none'};
			setTimeout(() => {
				this.styleFooter = { 'pointer-events' : 'initial'};
			}, 800);
		}
		
		
	}
	
	
	follow(selectedIndex:number = -1):void
	{
		if(selectedIndex != -1){
			
			let heroKeyName:string = "";
			if(this.indexScenario == 1) heroKeyName = 'jim';
			else if(this.indexScenario == 2) heroKeyName = 'bernard';
			else if(this.indexScenario == 3) heroKeyName = 'alice';
			
			this.userData.addLog(heroKeyName, this.infoDialog.answerLocKeys[selectedIndex]);
		}
		
		this.gameService.followNodes(this.node, this.level, selectedIndex);
	}
	
	
	clickFooter():void
	{
		if(this.blockDisplay == 'Didascalie'){
			this.clickNext();
		}
	}
	
	clickLog():void
	{
		console.log('click log');
		setTimeout(() => {
			this.modalService.open('history', 'modal_game');
		}, 50);
		
	}
	
	
	getTime(value:number):number
	{
		if(environment.skipTimeout) return 0.01;
		else return value;
	}
	
	
	
	animateJauge(delta:number, type:string):void
	{
		let prefix: string = (delta < 0 ) ? 'down' : 'up';
		this.soundPlayer.play('assets/sounds/cobraz_jauges_' + prefix + '.mp3', {
			duration: '19%',
			volume:0.3,
		});
		
		setTimeout(() => {
			if(type == 'A') this.jaugeAdisplay = this.ds.jaugeA + delta;
			else this.jaugeBdisplay = this.ds.jaugeB + delta;
		}, 0);
		setTimeout(() => {
			if(type == 'A') this.changeColorA = delta > 0 ? '#31B2A6' : '#E3563D';
			else this.changeColorB = delta > 0 ? '#31B2A6' : '#E3563D';
			if(type == 'A') this.deltaChangeA = delta > 0 ? 1 : -1;
			else this.deltaChangeB = delta > 0 ? 1 : -1;
		}, 700);
		setTimeout(() => {
			if(type == 'A') this.changeColorA = null;
			else this.changeColorB = null;
			if(type == 'A') this.deltaChangeA = 0;
			else this.deltaChangeB = 0;
		}, 1900);
		
		let classname:string = type == 'A' ? '.jauge_secret' : '.jauge_ethique';
		this.appAnimate.animateJaugeHeader(classname);
	}

	translateBubbleContent(locKey: string): string {
		let text = this.wordings.translate(locKey);
		
		if(text != undefined && text.includes('[Didascalie]')){
			text = text.replace('[Didascalie]', '');
			while(text.charAt(0) == ' ') text = text.substr(1);
			
			let tab = text.split('|');
			if(tab.length > 1)
				return tab[1];
			
			this.textDidascalie = text;
			return "";
		}
		return text;
	}

	translateDidascalieContent(locKey: string): string {
		let text = this.wordings.translate(locKey);
		
		if(text != undefined && text.includes('[Didascalie]')){
			text = text.replace('[Didascalie]', '');
			while(text.charAt(0) == ' ') text = text.substr(1);
			
			let tab = text.split('|');
			if(tab.length > 1)
				return tab[0];
			return text;
		}
		return "";
	}
	
	/* 
	public scroll(delta:number, distance:number):void
	{
		let elmt = this.talkerContent.nativeElement;
		$(elmt).animate({
			scrollTop: elmt.scrollTop + delta * distance,
		}, 630);
	}
	 */

}
