import { Component, OnInit, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { gameoverAnimation } from 'src/app/animations/home.animations';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { environment } from 'src/environments/environment';
import { MesureImpactStateService } from "src/app/services/mesure-impact-state.service";
import { WordingsService } from 'src/app/services/wordings.service';
declare var dmi: any;
declare var pipwerks;

type FormFieldData = ComboFormData | TextFormData | ComputedFormData;

export class FormElementDescriptor {
	public displayName: string;
	public configName: string;
	public type: string;
	public options: string[];
}

export class ComboFormData {
	public displayName: string;
	public configName: string;
	public type: string = "combo";
	public options: (string[] | Object);
	
}

export class TextFormData {
	public displayName: string;
	public configName: string;
	public type: string = "text";
	public placeholderText: string;
}

export class ComputedFormData {
	public configName: string;
	public type: string = "computed";
	public value: string;
	public displayName: string = "";
}

@Component({
	selector: 'app-gameover',
	templateUrl: './gameover.component.html',
	styleUrls: ['./gameover.component.scss'],
	animations: [gameoverAnimation],
})
export class GameoverComponent implements OnInit {

	@HostBinding('@animations') private animations;

	nickname: string;
	nicknamePlaceholder: string;
	nicknameInstruction: string;
	score: number;

	mesureImpactState: number = 0;
	skipped: boolean = false;

	firstName: string;
	lastName: string;
	email: string;
	optin: boolean = false;
	selectors: FormFieldData[];
	selectedOptions: string[] = [];
	formGroups: number[];

	constructor(
		private modalService: ModalsService,
		private api: ApiService,
		public ds: DataService,
		private userData: UserDataService,
		private impactService: MesureImpactStateService,
		private wordings: WordingsService
	) {

	}

	ngOnInit() {
		this.impactService.currentMessage.subscribe(message => this.mesureImpactState = message);
		// dmi.resetCookies();
		this.score = this.userData.data.currentScore;
		this.score = Math.round(this.score);
		if(pipwerks.SCORM.API.isFound)
		{
			// SCORM 2004 : cmi.core.lesson_status => cmi.completion_status
			pipwerks.SCORM.set('cmi.core.lesson_status', "completed");

			// SCORM 2004 : cmi.core.score_raw => cmi.score.raw
			pipwerks.SCORM.set('cmi.core.score_raw', ""+this.score);
			pipwerks.SCORM.save();

			// SCORM 2004 : cmi.core.student_name => cmi.learner_name
			this.nickname = pipwerks.SCORM.get('cmi.core.student_name');
		}
		this.nicknamePlaceholder = this.wordings.translate('ui.end.pseudonym.enter');
		this.nicknameInstruction = this.wordings.translate('ui.end.pseudonym.text');
		if(this.ds.uid === 'AdeoServices')
		{
			this.nicknamePlaceholder = "Nom Prénom";
			this.nicknameInstruction = "Entrez vos Nom et Prénom et renseignez votre Business Unit";
		}
		
		this.updateSelectorsList();
		while(this.selectedOptions.length < this.selectors.length)
		{	
			let selector = this.selectors[this.selectedOptions.length];
			if(selector.type === 'text')
				this.selectedOptions.push('');
			else
			{
				let options = (selector as ComboFormData).options;
				let defaultOption = options[Object.keys(options)[0]];
				if(typeof(defaultOption) == 'string')
					this.selectedOptions.push(defaultOption as string);
				else this.selectedOptions.push(Object.keys(options)[0]);
			}
		}
	}

	updateSelectorsList() {
		this.selectors = [];
		this.formGroups = [];
		for(let i=0; i<this.ds.departments.length; i++)
		{
			this.formGroups.push(0);
			this.generateFormFromConfig(this.ds.departments[i], this.selectedOptions, this.selectors, this.formGroups);
		}
	}

	// Generate a form from the config specified in the company specific data at data/company/[companyUID]/leaderboard_form.txt
	// a default_leaderboard_form.json is used when none is present
	// Example of forms are present in the DOC folder of the repository
	generateFormFromConfig(currentNode: FormFieldData, selectedOptions: string[], selectors: FormFieldData[], formGroups: number[])
	{
		let toAdd: FormElementDescriptor = {
			displayName: currentNode.displayName,
			configName: currentNode.configName,
			type: currentNode.type,
			options: []
		};
		if(currentNode.type == 'combo')
		{
			const options = (currentNode as ComboFormData).options;
			
			if(Array.isArray(options))
			{
				for(let option of (options as string[]))
				{
					toAdd.options.push(option);
				}
			}
			else if(typeof(options) == 'object')
			{
				for(let key in options as Object)
				{
					toAdd.options.push(key);
				}
			}
		}
		formGroups[formGroups.length - 1]++;
		this.selectors.push(toAdd);
		const selectorIndex = this.selectors.length - 1;
		if(this.selectedOptions !== undefined && this.selectedOptions.length > selectorIndex && this.selectedOptions[selectorIndex] != null)
		{
			switch(currentNode.type)
			{
				case "combo":
					let formData = (currentNode as ComboFormData);
					if(formData != undefined)
					{
						let selectedOption = formData.options[this.selectedOptions[selectorIndex]];
						if(selectedOption == undefined)
						{
							selectedOption = this.selectedOptions[selectorIndex];
						}
						if(typeof(selectedOption) == 'string')
						{
							
						}
						else if(Array.isArray(selectedOption))
						{
							let options = selectedOption as FormFieldData[];
							for(let key in options)
							{
								this.generateFormFromConfig(options[key], selectedOptions, selectors, formGroups);
							}
						}
						else
						{
							this.generateFormFromConfig(selectedOption as FormFieldData, selectedOptions, selectors, formGroups);
						}
					}
				break;
				default:
				break;
					
			}
		}
		else
		{
			switch(currentNode.type)
			{
				case "combo":
					const formData = currentNode as ComboFormData;
					let childNode = formData.options[Object.keys(formData.options)[0]];
					if(Array.isArray(childNode))
					{
						for(let node of childNode as FormFieldData[])
						{
							if(typeof(node) !== 'string')
								this.generateFormFromConfig(node, selectedOptions, selectors, formGroups);
						}
					}
					else
					{
						if(typeof(childNode) !== 'string')
							this.generateFormFromConfig(childNode, selectedOptions, selectors, formGroups);
					}
				break;
				case "text":
					
				break;
				default:
					
				break;
			}
		}
	}

	valid(): void {
		let score: number = this.userData.data.currentScore;
		if (environment.debug && score == 0) score = 255;
		score = Math.round(score);
		let uscores = this.userData.data.scores;
		// let scores:number[] = [uscores.chap1, uscores.chap2, uscores.chap3, uscores.end];
		let scores: number[] = [uscores.chap1, uscores.chap2, uscores.chap3];

		let data = {};
		for(let i in this.selectedOptions)
		{
			if(this.selectors[i].configName !== undefined)
				data[this.selectors[i].configName] = this.selectedOptions[i];
		}
		for(let selector of this.ds.departments)
		{
			if(selector.type == 'computed')
			{
				let value = (selector as ComputedFormData).value;
				var regExp = /\[[^\]]*\]/g;
				let toParse = value;
				let match = regExp.exec(toParse);
				let cursor = 0;
				let result = "";
				for(; match != undefined && match != null; )
				{
					let valueName = match[0].substring(1, match[0].length - 1);
					result += toParse.substring(cursor, match.index) + data[valueName];
					cursor = match.index + match[0].length;
					// toParse = toParse.substring(match.index + match[0].length);
					match = regExp.exec(toParse);
				}
			}
		}
		if(dmi.userIsFinished())
		{
			this.userData.data.scores.mi_bonus = 1200;
		}
		else 
		{
			this.userData.data.scores.mi_bonus = 0;
		}
		console.log(this.userData.data.scores);
		this.userData.save();
		this.api.saveScore(data['nickname'], this.ds.uid, JSON.stringify(data), "")
				.subscribe(resp => {

					let playerRank: string = '' + resp.data.playerRank;
					this.ds.leaderboard_user = this.ds.getLeaderboardHero(resp.data.leaderboard, playerRank);

					this.ds.leaderboard = this.ds.leaderboard_user;
					let p = this.ds.leaderboard;
					this.modalService.open('leaderboard', 'modal');
					this.userData.resetAll();
					this.userData.save();
				});
	}

	toggleOptin(event): void {
		this.optin = !this.optin;
	}

	launchForm(): void {
		this.modalService.open("mesureImpact");
	}

	skipForm(): void {
		this.skipped = true;
	}

	formIsCompleted(): string {
		return dmi.userIsFinished() ? "block" : "none";
	}

	displayImpactMeasureForm(): string {
		let isDisplayed = this.ds.company.impactMeasurement && this.mesureImpactState == 0 && !this.skipped && !dmi.userIsFinished();
		return isDisplayed && this.ds.uid !== 'sncf' ? "block" : "none";
	}

	formChoiceHidden(): string {
		let isDisplayed = !this.ds.versionAllowedForm || this.mesureImpactState == 1 || this.skipped || dmi.userIsFinished();
		return isDisplayed || this.ds.uid === 'sncf' ? "block" : "none";
	}

	onValueChanged(event: any) {
		let index = 0;
		for(let i=0; i<event.lineIndex; i++)
		{
			index += this.formGroups[i];
		}
		index += event.elementIndex;
		let value = event.value;
		while(this.selectedOptions.length < this.selectors.length)
		{	
			let selector = this.selectors[this.selectedOptions.length];
			if(selector.type === 'text')
				this.selectedOptions.push('');
			else
			{
				let options = (selector as ComboFormData).options;
				let defaultOption = options[Object.keys(options)[0]];
				if(typeof(defaultOption) == 'string')
					this.selectedOptions.push(defaultOption as string);
				else this.selectedOptions.push(Object.keys(options)[0]);
			}
		}
		this.selectedOptions[index] = value;
		if(this.selectors[index].type == 'combo')
		{
			for(let i=index+1; i<this.selectedOptions.length; i++)
			{
				this.selectedOptions[i] = null;
			}
			this.updateSelectorsList();
			for(let i=index+1; i < this.selectedOptions.length; i++)
			{
				let selector = this.selectors[i];
				let comboData = (selector as ComboFormData);
				if(comboData != undefined)
				{
					let options = comboData.options;
					this.selectedOptions[i] = options[Object.keys(options)[0]];
				}
			}
		}
	}

	getFormLineConfig(lineIndex: number): FormFieldData[]
	{
		let result: FormFieldData[] = [];
		let cursor = 0;
		for(let i=0; i<lineIndex; i++)
		{
			cursor += this.formGroups[i];
		}
		for(let i=0; i<this.formGroups[lineIndex]; i++)
		{
			result.push(this.selectors[cursor]);
			cursor++;
		}
		return result;
	}

	getSelectedOptions(lineIndex: number): string[]
	{
		let result: string[] = [];
		let cursor = 0;
		for(let i=0; i<lineIndex; i++)
		{
			cursor += this.formGroups[i];
		}
		for(let i=0; i<this.formGroups[lineIndex]; i++)
		{
			result.push(this.selectedOptions[cursor]);
			cursor++;
		}
		return result;
	}
}
