import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
  @ViewChild('container') container: ElementRef;

  constructor(private wordings: WordingsService) { }

  ngOnInit() {
  }

  getLanguageFlagUrl(lang: string)
  {
    return "url(" + environment.dataUrl + "/data/flags/btn_" + lang + ".png)"; 
  }
  
  getLanguageFlagPressedUrl(lang: string)
  {
    return "url(" + environment.dataUrl + "/data/flags/btn_" + lang + "_pressed.png)"; 
  }

  selectLanguage(languageIndex: string) {
    this.wordings.selectLanguage(languageIndex);
  }
}
