import { DataService } from './services/data.service';
import { ApiService } from './services/api.service';
import { Routes } from '@angular/router';
import { routes } from './app.routes';
import { environment } from '../environments/environment';
import { Component, Renderer2 } from '@angular/core';
import { RouterEventService } from './shared/navigation/router-event.service';
import { StorageService } from './shared/storage/storage.service';
import { SoundPlayerService } from './shared/sound/sound-player.service';
import { ModalsService } from './shared/navigation/modals.service';
import { fxAnimations } from './animations/fx.animations';
import { TimeoutService } from './shared/time/timeout.service';
import { UserDataService } from './services/user-data.service';
import { variable } from '@angular/compiler/src/output/output_ast';
import { GoogleAnalyticsService } from './shared/api/google-analytics.service';
import { BroadcasterService } from './shared/events/broadcaster.service';
import { FullscreenService } from './shared/utils/fullscreen.service';
import { PlatformService } from './shared/utils/platform.service';
import { PreloadImgService } from './shared/sound/preload-img.service';
import { constantes } from './constantes';
import { WordingsService } from './services/wordings.service';
declare var dmi: any;
declare var pipwerks:any;
declare function millisecondsToSCORMTime(milliseconds: number): string;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [fxAnimations],
})
export class AppComponent {

	routes: Routes = routes;
	env = environment;
	loaded: boolean;

	showMenu: boolean;
	showBack: boolean;
	bgVisible: number;

	errorCode = -1;
	hideLoading = false;

	private startTime: Date;


	constructor(
		private api: ApiService,
		private ds: DataService,
		private routerEvent: RouterEventService,
		private storage: StorageService,
		private soundPlayer: SoundPlayerService,
		private modalService: ModalsService,
		private timeout: TimeoutService,
		private userData: UserDataService,
		private ga: GoogleAnalyticsService,
		private renderer: Renderer2,
		private broadcaster: BroadcasterService,
		private fs: FullscreenService,
		private platform: PlatformService,
		private preloadImg: PreloadImgService,
		private wordings: WordingsService,
	) {
        pipwerks.SCORM.init();
		pipwerks.SCORM.save();
		// console.log(pipwerks.SCORM.get('cmi.core.total_time'));

		// SCORM 2004 : cmi.core.lesson_status => cmi.completion_status
		if(pipwerks.SCORM.get('cmi.core.lesson_status') != "completed")
		{
			pipwerks.SCORM.set('cmi.core.lesson_status', "incomplete");
		}
		ds.modeClient = (!location.href.match(/\?test/));
		ds.modeClient = !ds.modeClient;
		this.startTime = new Date();
		// ds.modeClient = false;

		this.timeout.enabled = !this.env.skipTimeout;
		this.userData.init(ds.modeClient);


		let tab = location.href.match(new RegExp("uid=(.+)$"));
		this.ds.uid = tab && tab.length >= 1 && tab[1];
		this.ds.projectId = constantes.dmi_key;
		this.ds.dmiEndpointUrl = constantes.dmi_endpoint;
		this.ds.dmiBonusPoints = constantes.dmi_bonusPoints;
		this.ds.dmiFormNum = constantes.dmi_formNum;
		this.ds.versionAllowedForm = this.ds.uid == environment.defaultCompany || constantes.versionUIDsAllowedForms.includes(this.ds.uid);
		this.ds.modeConnexion = (this.ds.uid == null || this.ds.uid == environment.defaultCompany) && environment.promptConnexion;

		let uid = tab && tab.length >= 1 && tab[1];
		if (uid == null)
			uid = environment.defaultCompany;
		if (!document.cookie.includes("registered=")) {
			this.api.registerUser(uid).subscribe(resp => {

			});
			document.cookie = "registered=true; expires=9999-12-31T12:00:00.000Z";
		}

		this.modalService.addOutletListener(null, () => {

			if (!this.storage.getBoolean('tracking')) this.ga.emitEvent('alive', 'alive');

		});

		//init storage
		if (!this.storage.isset('sound')) this.storage.setBoolean('sound', true);
		if (!this.storage.isset('tracking')) {
			this.storage.setBoolean('tracking', false);
			this.ga.enabled = true;
		}
		this.ga.setUid(uid);



		// routerEvent.setListOutlets(['modal_game', 'modal_menu', 'modal']);
		routerEvent.setRoutes(routes);

		/* 
		routerEvent.addListener(
			['landing'],
			() => {
				this.timeout.setTimeout(() => {
					this.bgVisible = 1;
				}, 1000);
			}
		); 
		*/


		routerEvent.addListeners(
			[
				{
					routes: ['landing'],
					handlerEnter: (route) => {
						this.timeout.setTimeout(() => {
							this.bgVisible = 1;
						}, 1000);
					}
				},
				{
					routes: ['gameover2', 'chapter-end', 'gameover'],
					handlerEnter: (route) => this.bgVisible = 2
				},
			],
			() => this.bgVisible = 1
		);


		console.log('routerEvent.addListener');
		routerEvent.addListeners(
			[{
				routes: ['gameover2'],
				handlerEnter: (route) => {
					this.soundPlayer.pause('assets/sounds/gametheme.mp3');
					this.soundPlayer.play('assets/sounds/cobraz_gameover_bad.mp3', {
						volume: 0.2,
						forceStart: false,
						group: 'music',
					});
				}
			},
			{
				routes: ['chapter-end'],
				handlerEnter: (route) => {
					this.soundPlayer.pause('assets/sounds/gametheme.mp3');
					this.soundPlayer.play('assets/sounds/cobraz_gameover_good.mp3', {
						volume: 0.2,
						forceStart: false,
						group: 'music',
					});
				}
			},
			{
				routes: ['game'],
				handlerEnter: (route) => {
					this.soundPlayer.play('assets/sounds/gametheme.mp3', {
						volume: 0.2,
						loop: true,
						forceStart: false,
						group: 'music',
					});
				},
			},

			{
				routes: ['chapter-start'],
				handlerEnter: (route) => {

					let tutoVisible: boolean = this.routerEvent.isRouteActive('tuto');
					if (!tutoVisible) {
						this.soundPlayer.pause('assets/sounds/gametheme.mp3');
					}

				},
			}],
			() => {
				this.soundPlayer.pause('assets/sounds/gametheme.mp3');
			}, true
		);


		routerEvent.addListener(
			['create-account', 'credits', 'leaderboard'],
			(route) => {
				if (route == 'leaderboard') {
					this.showBack = !this.routerEvent.isRouteActive('gameover');
				}
				else this.showBack = true;

			},
			() => this.showBack = false,
			true,
		);


		routerEvent.addListener(
			['chapter-start', 'game', 'quiz', 'gameover', 'leaderboard', 'menu', 'tuto'],
			(route: string) => {

				if (route == 'game') {
					let indexScenario: number = this.userData.data.indexScenario;
					this.showMenu = [0, 4].indexOf(indexScenario) == -1;
				}
				else this.showMenu = true;
			},
			() => this.showMenu = false,
			true,
		);



		this.api.getData(this.ds.uid).toPromise()
			.then(resp => {

				this.ds.company = resp.data.company;
				if(this.ds.company)
				{
					this.ds.company.impactMeasurement = resp.data.company.impactMeasurement === "1";
					this.ds.quiz = resp.data.quiz;
					this.wordings.loadWordings(resp.data.wordings, resp.data.company.availableLanguages.split(','));
					this.ds.scenarios = resp.data.scenarios;
				
					if (this.ds.company.impactMeasurement) {
						// initiate user_id 
						let uidPost = { uid: this.ds.uid };
						dmi.initiateUserUid(null, this.ds.projectId, this.ds.dmiFormNum, this.ds.dmiEndpointUrl, JSON.stringify(uidPost));

						// this.userData.data.currentScore += dmi.userIsFinished() ? this.ds.dmiBonusPoints : 0;
					}

					if(resp.data.leaderboard_departments != null)
					{
						this.ds.departments = resp.data.leaderboard_departments;
					}
				}

				if (resp.success) {
					return this.api.getParams().toPromise();
				}
				else {

					this.hideLoading = true;
					throw new Error(resp.data.errorMessage);
				}

			})

			.then(resp => {

				this.ds.params = resp;
				this.loadImg();

			})
			.catch((err) => {
				this.ds.uiMessage = err.message;
			});




		this.api.getLeaderboard(this.ds.uid)
			.subscribe(resp => {
				this.ds.leaderboard_global = resp.data.leaderboard;
			});

		if (this.ds.versionAllowedForm) {
			// initiate user_id 
			let uidPost = { uid: this.ds.uid };
			dmi.initiateUserUid(null, this.ds.projectId, this.ds.dmiFormNum, this.ds.dmiEndpointUrl, JSON.stringify(uidPost));

			// this.userData.data.currentScore += dmi.userIsFinished() ? this.ds.dmiBonusPoints : 0;
		}

		//music
		let musicEnabled: boolean = this.storage.getBoolean('sound');

		if (musicEnabled) this.soundPlayer.enable();
		else this.soundPlayer.disable();

		this.soundPlayer.addSelectorEvent('snd_answer', 'mousedown', 'assets/sounds/cobraz_btn_answerv2.mp3');
		this.soundPlayer.addSelectorEvent('snd_click', 'mousedown', 'assets/sounds/click.mp3');

		this.broadcaster.addSelectorEvent('enable_fs', 'mouseup', () => {
			// if(this.platform.isAndroid()) this.fs.openFullscreen();
			if (!environment.debug) this.fs.openFullscreen();
		});


		document.addEventListener('visibilitychange', () => {
			if (document.hidden) {
				console.log('focus out');
				if (this.storage.getBoolean('sound')) this.soundPlayer.disable();
			}
			else {
				console.log('focus in');
				if (this.storage.getBoolean('sound')) this.soundPlayer.enable();
			}
		}, false);





	}

	onUnload(): void {
		let sessionDuration = new Date().valueOf() - this.startTime.valueOf();
		console.log(sessionDuration);
		let seconds = Math.floor(sessionDuration / 1000);
		let minutes = Math.floor(seconds / 60);
		let hours = Math.floor(minutes / 60);
		let formattedDuration = Math.floor(hours/10).toString() + (hours%10).toString() + ":"
			+ Math.floor(minutes/10).toString() + (minutes%10).toString() + ":"
			+ Math.floor(seconds/10).toString() + (seconds%10).toString();
		// SCORM 2004 => cmi.core.session_time => cmi.session_time
		pipwerks.SCORM.set('cmi.core.session_time', formattedDuration);
		pipwerks.SCORM.quit();
	}

	loadImg(): void {
		let prefix: string = environment.dataUrl;

		//preloading
		if(this.ds.company)
		{
			this.preloadImg.preload([
				'assets/dynamic/decors/bg_chapter_1.png',
				'assets/dynamic/decors/bg_chapter_2.png',
				'assets/dynamic/decors/bg_chapter_3.png',
				'assets/dynamic/decors/BG_bureau.png',
				'assets/dynamic/decors/BG_bombe_intro.png',
				'assets/dynamic/decors/BG_ascenseur.png',
				'assets/dynamic/decors/BG_detente.png',
				'assets/dynamic/decors/BG_labo.png',
				'assets/dynamic/decors/BG_salleInfo.png',
	
				'assets/dynamic/persos/alice_neutre.png',
				'assets/dynamic/persos/bernard_neutre.png',
				'assets/dynamic/persos/cara_neutre.png',
				'assets/dynamic/persos/donald_neutre.png',
				'assets/dynamic/persos/jane_neutre.png',
				'assets/dynamic/persos/jim_neutre.png',
				'assets/dynamic/persos/rudy_neutre.png',
	
				'assets/images/bg/bg.png',
	
				prefix + this.ds.company["logoUrl"],
	
			], () => {
				this.loaded = true;
				console.log('loaded true');
	
			});

		}
		else this.loaded = true;

	}


	ngOnInit(): void {

	}






	clickMenu(value: boolean): void {
		if (value) {
			this.modalService.open('menu', 'modal_menu');

			setTimeout(() => {
				if (this.routerEvent.isOutletActive('modal_game')) {
					this.modalService.close('modal_game');
				}
			}, 50);

		}
		else this.modalService.close('modal_menu');
	}

	clickBack(): void {
		if (this.routerEvent.isOutletActive('modal')) {
			console.log('modal opened');
			this.modalService.close('modal');
		}
		else {
			console.log('modal closed');
		}

	}


}
