import { query, transition, trigger, group, useAnimation, animate, animation, animateChild, style, stagger, sequence } from '@angular/animations';
import { dsl } from './app.animations';
import { Easing } from '../shared/animation/animation.interface';



/*
decorator :
animations: [xAnimations],
declarations :
@HostBinding('@animations') private animations;

composition :
query('.dsl_x', group([
	dsl.zoomIn(0, {scaleStart: 2}),
])),
*/

export const chapterStartAnimation = [
	
  trigger('animations', [
		
    transition(':enter', group([
			 
			query('.dsl_fade', dsl.reset().fadeIn(0)),
			query('.dsl_right', dsl.slideIn(0.5, { dir: 'right' })),
			query('.dsl_left', dsl.slideIn(0.4, {dir:'left', useMargin: true, easeIn:Easing.EASE_OUT, fade:false, distanceIn:650, timeIn:0.3})),
			
		])),
		
		transition(':leave', group([
			
			query('.dsl_left', dsl.reset().slideOut(0.3, {dir:'left', useMargin: true, easeIn:Easing.EASE_OUT, fade:false, distanceIn:650})),
			query('.dsl_right', dsl.slideOut(0.3, { dir: 'right' })),
			query('.dsl_fade', dsl.reset().fadeOut(0.5)),
			
		])),
		
  ])
];

