import { DataService } from './data.service';
import { CallbackInfo, createMock as createMockInfo } from './../model/callbackinfo.interface';
import { GameMode, NodeType, CallbackType } from '../model/types';
import { TraceService } from './../shared/debug/trace.service';
import { GameNodeTarget } from '../model/gamenodetarget.interface';
import { GameNode, Graph } from './../model/gamenode.interface';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WordingsService } from './wordings.service';

@Injectable({
  providedIn: 'root'
})
export class GameService {
	
	private graph:Graph;
	// private mode:string;	//tentative de le set en mode global, mais ptet gestion recursive nécéssaire
	private isChapter:boolean;
	dataActions:{};
	
	callback:(output:CallbackInfo, node:GameNode, level:number)=>void;
	
	
	
	constructor(
		private trace:TraceService,
		private ds:DataService,
		private wordings: WordingsService,
	) { }
	
	
	init(graph:Graph, nodeId:string, dataActions:any, isChapter:boolean):void
	{
		this.graph = graph;
		// this.mode = '';
		
		this.dataActions = dataActions || {};
		this.isChapter = isChapter;
		
		//calculate additional props
		
		for(let k in this.graph){
			let node = this.graph[k];
			node.id = k;
			
			
			if(environment.debug){
				for(let kk in node.targets){
					let nodeTarget:GameNodeTarget = node.targets[kk];
					for(let kkk in nodeTarget.actions){
						let action:string = nodeTarget.actions[kkk];
						if(action.substr(0, 6) == '$jauge') nodeTarget.actions[kkk] = "$jauge1+=13";
					}
				}
			}
			
			
		}
		
		
		
		let firstNode = this.graph[nodeId];
		this.rec(firstNode, null, 0);
		
		
	}
	
	
	private rec(node:GameNode, parentNode:GameNode, level:number)
	{
		let output:CallbackInfo = this.handleNode(node, parentNode, level);
		
		if(output.callback) this.callback(output, node, level);
		if (output.continue) this.followNodes(node, level);
		
		
	}
	
	
	
	
	//convert GameNode to CallbackInfo
	
	private handleNode(node:GameNode, parentNode:GameNode, level:number):CallbackInfo
	{
		let output:CallbackInfo = createMockInfo();
		output.continue = true;
		output.callback = false;
		output.nodeId = node.id;
		
		//todo remove this.mode
		/* 
		if(node.type == NodeType.Dialogue_Begin) this.mode = GameMode.Dialog;
		else if(node.type == NodeType.Dialogue_End) this.mode = '';
		else if(node.type == NodeType.Ellipse_Begin) this.mode = GameMode.Ellipse;
		else if(node.type == NodeType.Ellipse_End) this.mode = '';
		 */
		
		this.trace.tracerec('node : '+node.id+', '+node.type, level);
		
		//if choice
		// if(this.mode == GameMode.Dialog && node.talker){
		if(node.type == NodeType.DialogElement && node.talker && node.talker != 'time'){
			
			let text:string = this.wordings.translate(node.voiceFile);
			output.voiceFile = node.voiceFile;
			output.questionLocKey = node.voiceFile;
			
			if(text.includes('[Didascalie]')){
				output.type = CallbackType.Didascalie;
			}
			else{
				output.type = CallbackType.Dialog;
			}
			
			/* 
			if(environment.debug){
				let str = output.questionText;
				for(let i=0;i<10;i++) output.questionText += '\n' + str;
			}
			 */
			
			
			if(environment.debug) node.talker = node.talker.replace('content', 'neutre');
			let talkers:string[];
			
			if(['empty'].indexOf(node.talker) == -1) talkers = node.talker.split('|');
			else talkers = [];
			
			
			output.continue = false;
			output.callback = true;
			output.talkers = talkers;
			if (talkers && talkers.length > 0){
				let tab = talkers[0].split('_');
				output.talkerName = tab[0];
			}
			
			node.targets = node.targets.filter((obj:GameNodeTarget) => this.evalConditions(obj.conditions));
			let nodeTargets:GameNode[] = node.targets.map((obj:GameNodeTarget) => this.graph[obj.id]);
			nodeTargets = nodeTargets.filter((node:GameNode) => node.talker == '');
			
			output.actions = nodeTargets.map((obj:GameNode) => obj.targets[0].actions);
			
			output.answerLocKeys = nodeTargets.map((obj:GameNode) => obj.voiceFile);
		}
		
		// if(this.mode == GameMode.Ellipse && node.type == NodeType.DialogElement){
		if(node.type == NodeType.DialogElement && node.talker == 'time'){
			
			output.type = CallbackType.Ellipse;
			output.text = this.wordings.translate(node.voiceFile);
			output.continue = false;
			output.callback = true;
			
		}
		
		else if(node.type == NodeType.Background){
			
			output.callback = true;
			output.type = CallbackType.Background;
			output.background = node.background;
			
		}
		
		else if(node.type == NodeType.End){
			
			output.callback = true;
			output.type = CallbackType.End;
			
		}
		
		
		return output;
	}
	
	
	
	public followNodes(node:GameNode, level:number, selectedIndex:number = -1)
	{
		let len:number = node.targets ? node.targets.length : 0;
		let start=0; 
		let end = len;
		
		//answer selected
		if(selectedIndex != -1){
			start = selectedIndex;
			end = start+1;
		}
		//conditions
		else{
			
			for(let i=start;i<end;i++){
				
				let target:GameNodeTarget = node.targets[i];
				if(target.conditions) target.resultCondition = this.evalConditions(target.conditions);
				else target.resultCondition = true;
				
				if(target.resultCondition){
					start = i;
					end = start+1;
					break;
				}
			}
		}
		
		for(let i=start;i<end;i++){
			
			let target:GameNodeTarget = node.targets[i];
			if(target == null){
				console.log('');
			}
			
			if(target.actions){
				this.execActions(target.actions);
				
			}
			
			
			let newNode:GameNode = this.graph[target.id];
			this.rec(newNode, node, level + 1);
			
		}
	}
	
	
	private execActions(actions:string[]):void
	{
		
		for(let k in actions){
			let action = actions[k];
			
			action = action.replace('$', 'this.dataActions.');
			eval(action);
			this.dataActions;
			
		}
		
		for(let key in this.dataActions){
			if(key.substr(0, 5)=="jauge"){
				if(this.dataActions[key] > 100) this.dataActions[key] = 100;
				if(this.dataActions[key] < 0) this.dataActions[key] = 0;
			}
		}
		
		
	}
	
	
	private evalConditions(conditions:string[]):boolean
	{
		let output:boolean = true;
		
		for(let k in conditions){
			let cond = conditions[k];
			cond = cond.replace('$', 'this.dataActions.');
			this.dataActions;
			let result:boolean = eval(cond);
			if(!result){
				output = false;
				break;
			}
		}
		
		
		return output;
	}
	
	
	
	
}
