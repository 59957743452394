import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChange, SimpleChanges, ApplicationRef, NgZone } from '@angular/core';
import * as ProgressBar from 'progressbar.js';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';

@Component({
	selector: 'app-jauge',
	templateUrl: './jauge.component.html',
	styleUrls: ['./jauge.component.scss']
})
export class JaugeComponent implements OnInit {
	
	@Input() progress:number;
	@Input() color:string;
	@Input() isSmall:boolean;
	@Input() textColor:string;
	@Input() animate:boolean = false;
	@Input() deltaChange:number = 0;
	
	textColorDisplay:string;
	
	data:any;
	animPercent: number = 0;
	percent:number = 0;
	@Input() changeColor:string;
	
	@ViewChild('progress') progressElmt:ElementRef;
	
	@ViewChild('progress_down') progressDownElmt:ElementRef;
	@ViewChild('progress_up') progressUpElmt:ElementRef;
	
	@ViewChild('progress_back') progressElmtBack:ElementRef;
	
	textStyle:any;
	
	circle:any;
	circle_up:any;
	circle_down:any;
	
	downVisible:boolean;
	upVisible:boolean;

  constructor(
		private animateService:TweenAnimateService,
		private app:ApplicationRef,
		private ngZone: NgZone,
	) { }

	ngOnInit() {

		var circle_back = new ProgressBar.Circle(this.progressElmtBack.nativeElement, {
			color: 'white',
			duration: 0,
			strokeWidth: 6,
		});
		circle_back.animate(1);

		var circle = new ProgressBar.Circle(this.progressElmt.nativeElement, {
			color: this.color,
			duration: 0,
			strokeWidth: 6,
		});

		var circle_up = new ProgressBar.Circle(this.progressUpElmt.nativeElement, {
			color: '#31B2A6',
			duration: 0,
			strokeWidth: 6,
		});
		var circle_down = new ProgressBar.Circle(this.progressDownElmt.nativeElement, {
			color: '#E3563D',
			duration: 0,
			strokeWidth: 6,
		});

		this.circle = circle;
		this.circle_up = circle_up;
		this.circle_down = circle_down;
		this.update();

		if (this.isSmall) {

			if (!this.textStyle) this.textStyle = {};
			this.textStyle['font-size'] = '46px';
			this.textStyle['top'] = '7px';

		}
		//font-size:46px, top :7px

	}


	ngOnChanges(changes: SimpleChanges): void {
		if (changes.progress) {
			this.update();
		}
		if (changes.textColor) {
			this.textColorDisplay = this.textColor;
		}
		if (changes.changeColor) {
			this.textColorDisplay = this.changeColor || this.textColor;
		}
		if (changes.deltaChange) {
			this.downVisible = this.deltaChange < 0;
			this.upVisible = this.deltaChange > 0;
		}
	}


	update(): void {
		if (this.circle) {

			if (this.progress < 0) this.progress = 0;
			if (this.progress > 100) this.progress = 100;

			if (this.animate) {

				setTimeout(() => {
					this.circle.animate(this.progress / 100, {
						duration: 600,
					});
					this.circle_up.animate(this.progress / 100, {
						duration: 600,
					});
					this.circle_down.animate(this.progress / 100, {
						duration: 600,
					});
				}, 1000);

				this.animateService.reset().tweenValue(this, 0.6, {
					prop: 'animPercent',
					endValue: Math.round(this.progress),
					time: 0.6,
					round: true,
					onUpdate: () => {this.ngZone.run(() => {this.percent = this.animPercent;})},
				});
			}
			else {
				this.circle.set(this.progress / 100);
				this.circle_up.set(this.progress / 100);
				this.circle_down.set(this.progress / 100);

				this.percent = Math.round(this.progress);
			}

			if (!this.textStyle) this.textStyle = {};
			this.textStyle.color = this.textColor;

		}
	}

}
