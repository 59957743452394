import { UserDataService } from 'src/app/services/user-data.service';
import { NavigationService } from './../../services/navigation.service';
import { Component, OnInit, HostBinding, ApplicationRef, Renderer2, ViewChild, ElementRef, NgZone } from '@angular/core';
import { chapterendAnimation } from 'src/app/animations/chapterend.animations';
import { DataService } from 'src/app/services/data.service';
import {TweenMax, Linear} from "gsap";
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { TimeoutService } from 'src/app/shared/time/timeout.service';
import { environment } from 'src/environments/environment';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { Easing } from 'src/app/shared/animation/animation.interface';
import { Rank } from 'src/app/model/rank.interface';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-chapter-end',
  templateUrl: './chapter-end.component.html',
	styleUrls: ['./chapter-end.component.scss'],
	animations: [chapterendAnimation],
})
export class ChapterEndComponent implements OnInit {
	
	@HostBinding('@animations') private animations;
	@ViewChild('score_minus') elmtScoreMinus:ElementRef;
	
	data:any;
	tempData: any;
	numChapter:number;
	
	label_jauge1:string;
	label_jauge2:string;
	scoreMinus:number;
	
  constructor(
		public ds:DataService,
		private modalService:ModalsService,
		private timeout:TimeoutService,
		private navigation:NavigationService,
		private userData:UserDataService,
		private animate:TweenAnimateService,
		private app:ApplicationRef,
		private renderer:Renderer2,
		private ngZone: NgZone,
		private wordings: WordingsService,
	) { }
	
  ngOnInit() {
		if(!this.ds.scoreChapter) this.ds.scoreChapter = 245;
		
		this.data = {
			jaugeA: this.ds.jaugeA || 40,
			jaugeB: this.ds.jaugeB || 60,
			score: this.userData.data.currentScore - this.ds.scoreChapter,
		};
		this.tempData = {
			jaugeA: this.ds.jaugeA || 40,
			jaugeB: this.ds.jaugeB || 60,
			score: this.userData.data.currentScore - this.ds.scoreChapter,
		};
		
		this.numChapter = this.userData.data.indexScenario - 1;
		let ptsGameover:number = +this.ds.params.score.pts_per_gameover;
		
		let nbGameover:number = this.userData.data.ngGameover;
		if(environment.debug) nbGameover = 1;
		
		let score1:number = this.userData.data.currentScore + ptsGameover * nbGameover;
		let score2:number = this.userData.data.currentScore;
		
		let lapsTimeGO:number = 0.7;
		let timeAnimGameover:number = nbGameover * lapsTimeGO;
		this.scoreMinus = ptsGameover;
		
		this.animate.reset().tweenValue(this.tempData, 2.8, {
			time: 1.6,
			prop: 'score',
			ease: Easing.NONE, 
			endValue: score1,
			onUpdate: () => this.ngZone.run(() => {
				this.data.score = this.tempData.score;
			}),
			round:true,
		});
		
		this.animate.tweenValue(this.tempData, 2.5, {
			time: timeAnimGameover,
			prop: 'score',
			ease: Easing.NONE, 
			endValue: score2,
			onUpdate: () => this.ngZone.run(() => {this.data.score = this.tempData.score}),
			round:true,
		});
		
		
		let intervalGO:number = timeAnimGameover / (nbGameover);
		for(let i=0;i<nbGameover;i++){
			let delay = i==0 ? 0.0 : intervalGO;
			this.animate.animBonus('.score_minus', delay, {
				time:lapsTimeGO,
				useMargin: false,
				startValue: -45,
				endValue: 15,
			});
		}
		
		this.animate.reset().flash('.flash', 5.3 + timeAnimGameover, { time: 1.2 });
		
		let indexScenario = this.userData.data.indexScenario - 1;
		this.label_jauge1 = 'ui.game.jauge' + (indexScenario*2-1);
		this.label_jauge2 = 'ui.game.jauge' + (indexScenario*2-0);
		
		this.userData.data.ngGameover = 0;
		this.userData.save();
	}
	
	clickContinue():void
	{
		this.navigation.afterChapterEnd();
	}
}
