import { Injectable } from '@angular/core';
import { UserData, createMock } from '../model/userdata.interface';
import { StorageService } from '../shared/storage/storage.service';
import { environment } from 'src/environments/environment';
declare function encryptProgression(score);
declare function decryptProgression();

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

	public data:UserData;
	
	
	constructor(
		private storage:StorageService,
	) { }
	
	
	public init(modeTest:boolean):void
	{
		if(localStorage.getItem("selectedLanguage") == null){
			localStorage.setItem("selectedLanguage", "fr_FR");
			window.location.reload();
		}
		this.data = decryptProgression();
		console.log(this.data);
		if(!this.data){
			this.data = createMock();
			this.save();
		}
		
		if(!this.data.indexScenario) this.data.indexScenario = 0;
		
		
		if(modeTest){
			this.data.indexScenario = 3;
			this.data.indexProgression = 5;
			this.data.nodeId = null;
			this.data.currentScore = 2255;
			this.data.dataActions = {
				jauge1:50,
				jauge2:50,
				jauge3:50,
				jauge4:50,
				jauge5:50,
				jauge6:50,
			};
			this.data.ngGameover = 0;
			this.data.scores= {
				chap1: 1785,
				chap2: 2225
			}
		}
		
		if(environment.debug){
			this.data.indexScenario = 1;
			this.data.indexProgression = 7;
			// this.data.nodeId = "n23::n9";
			this.data.nodeId = null;
			
			this.data.currentScore = 0;
			this.data.dataActions = {
				jauge1:50,
				jauge2:50,
				jauge3:90,
				jauge4:90,
				jauge5:80,
				jauge6:20,
			};
			this.data.ngGameover = 3;
			this.data.scores= {
				chap1: 20,
				chap2: 30,
				chap3: 40,
				end: 50,
			}
			
			
		}
		
		
	}
	
	
	public addLog(talker:string, msg:string, isDidascalie:boolean = false):void
	{
		let item:any;
		let logs = this.data.logs;
		
		let exists:boolean = !logs.every(item => item.talker != talker || item.msg != msg);
		if(!exists) this.data.logs.push({
			talker, 
			msg, 
			isDidascalie
		});
	}
	
	
	public resetChapter():void
	{
		this.data.dataActions = null;
		this.data.logs = [];
		this.data.nodeId = null;
	}
	
	public resetAll():void
	{
		this.resetChapter();
		this.data.bg = null;
		this.data.currentScore = 0;
		this.data.indexScenario = 0;
		this.data.scores = {};
	}
	
	public saveScore(score:number, indexScenario:number):void
	{
		let key:string;
		//score = 1,2,3,5
		if(indexScenario >= 1 && indexScenario <= 3) key = 'chap' + indexScenario;
		else if(indexScenario == 5) key = 'end';
		else throw new Error('wrong indexScenario : '+indexScenario);
		this.data.scores[key] = score;
	}
	
	public save():void
	{
		encryptProgression(this.data);
	}
	
}
