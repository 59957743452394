import { Component, OnInit, HostBinding } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { TimeoutService } from 'src/app/shared/time/timeout.service';
import { MathService } from 'src/app/shared/math/math.service';
import { environment } from 'src/environments/environment';
import { ellipseAnimation } from 'src/app/animations/game.animations';
import { SoundPlayerService } from 'src/app/shared/sound/sound-player.service';

@Component({
  selector: 'app-didascalie',
  templateUrl: './didascalie.component.html',
	styleUrls: ['./didascalie.component.scss'],
	animations:[ellipseAnimation],
})
export class DidascalieComponent implements OnInit {
	
	@HostBinding('@animations') private animations;
	
	text:string;

  constructor(
		private ds:DataService,
		private timeout:TimeoutService,
		private math:MathService,
		private soundPlayer:SoundPlayerService,
	) { }

  ngOnInit() {
		
		this.text = '';
		let time:number = environment.debug ? 1 : 1300;
		
		this.timeout.setTimeout(() => {
			this.addText(0);
		}, time);
		
	}
	
	addText(index:number):void
	{
		if(index == this.ds.textDidascalie.length){
			return;
		}
		
		this.text += this.ds.textDidascalie.charAt(index);
		
		let time:number = 100 + this.math.random(-30, 30, 1);
		if(environment.skipTimeout || environment.debug) time = 1;
		
		this.timeout.setTimeout(() => {
			this.addText(index + 1);
			
			let prefix:string;
			if(!prefix && this.math.getRandProbability(0.25)) prefix = '1';
			if(!prefix && this.math.getRandProbability(0.25)) prefix = '2';
			if(!prefix && this.math.getRandProbability(0.25)) prefix = '3';
			if(!prefix) prefix = '4';
			
			this.soundPlayer.play('assets/sounds/cobraz_elipse_' + prefix + '.mp3');
		}, time);
	}

}
