import { ChapterItem } from './../model/chapteritem.interface';
import { Company } from './../model/company.interface';
import { QuizItem } from '../model/quizitem.interface';
import { Graph } from './../model/gamenode.interface';
import { Injectable } from '@angular/core';
import { scaledAnchorProp, scaledOptions } from '../shared/directives/scaled.directive';
import { Rank } from '../model/rank.interface';

export type QuizLocalizedItem = { [key: string]: QuizItem };
export type QuizData = QuizLocalizedItem[];

@Injectable({
	providedIn: 'root'
})
export class DataService {

	uiMessage: string;

	public uid: string;
	public versionAllowedForm: boolean;
	public projectId: string;
	public dmiEndpointUrl: string;
	public dmiBonusPoints: number;
	public dmiFormNum: number;
	opentutoMenu: boolean;

	company: Company;
	scenarios: ChapterItem[];
	departments: any;

	jaugeA: number;
	jaugeB: number;

	textDidascalie: string;
	modeClient: boolean;
	params: any;

	scoreChapter: number;
	typeCredit: string;


	quiz: QuizItem[];
	leaderboard: Rank[];
	leaderboard_global: Rank[];
	leaderboard_user: Rank[];
	leaderboard_endchapter: Rank[];

	modeConnexion: boolean;



	scaledOptions: scaledOptions = {
		mode: 'contain',
		uiWidth: 1200,
		uiHeight: 675,
		anchor: scaledAnchorProp.CENTER,
	};
	scaledEnabled: boolean = true;


	getLeaderboardHero(leaderboard: Rank[], playerRank: string): Rank[] {
		//set hero where appropriate
		for (let k in leaderboard) {
			;
			let item = leaderboard[k];
			if (item.rank == playerRank) item.isHero = true;
		}
		return leaderboard;
	}



	constructor() { }



}
