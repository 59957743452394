export interface UserData{
	
	logs:LogItem[],
	
	nodeId:string,
	indexScenario:number,
	indexProgression:number;
	dataActions:any,
	bg:string,
	
	currentScore:number,
	ngGameover:number,
	
	scores:{
		chap1?:number,
		chap2?:number,
		chap3?:number,
		end?:number,
		mi_bonus?: number,
	},
};
export interface LogItem{
	talker:string,
	msg:string,
	isDidascalie?:boolean,
}



export function createMock(data:any = null):UserData{
	let output:UserData = {
		
		logs:[],
		nodeId:null,
		indexScenario:0,
		indexProgression:0,
		dataActions:{},
		bg:null,
		currentScore:0,
		ngGameover:0,
		scores:{},
	};
	output = {...output, ...data};
	return output;
};