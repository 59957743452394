// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	
	// Webservice root url
	BASE_URL:'https://cobrazero.dowino.com/game',
	// assets webroot location
	dataUrl: 'https://cobrazero.dowino.com/',
	defaultCompany: 'upwjtUqBLS3FDo0wL79Ns8mTQVGxU3ql',
	promptConnexion: true,
	production: false,
	debug:false,
	mockApi:false,
	autoClick:false,
	skipTimeout:false,
	
};


/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
