import { Component, OnInit, HostBinding } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { homeAnimation } from 'src/app/animations/home.animations';
import { FullscreenService } from 'src/app/shared/utils/fullscreen.service';
import { PlatformService } from 'src/app/shared/utils/platform.service';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { Easing } from 'src/app/shared/animation/animation.interface';
import { AnimateBase } from 'src/app/shared/animation/animate.base.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	animations: [homeAnimation],
})
export class HomeComponent implements OnInit {
	
	@HostBinding('@animations') private animations;
	
  constructor(
		private ds:DataService,
		private modalsService:ModalsService,
		private animate:TweenAnimateService,
		private navigation:NavigationService,
		private wordings: WordingsService,
	) { }

  ngOnInit() {
		
  }

	
	clickConnect():void
	{
		this.modalsService.open('login');
	}
	
	
	clickPlay():void
	{
		this.navigation.startGame();
	}
}
