import { ModalHistoryComponent } from './modals/modal-history/modal-history.component';
import { ConfirmquitComponent } from './pages/confirmquit/confirmquit.component';
import { MenuComponent } from './pages/menu/menu.component';
import { ChapterStartComponent } from './pages/chapter-start/chapter-start.component';
import { HomeComponent } from './pages/home/home.component';
import { LandingComponent } from './pages/landing/landing.component';
import { PageGameComponent } from './pages/page-game/page-game.component';
import { RouterModule, Routes } from '@angular/router';
import { QuizComponent } from './pages/quiz/quiz.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { ChapterEndComponent } from './pages/chapter-end/chapter-end.component';
import { CreditsComponent } from './pages/credits/credits.component';
import { GameoverComponent } from './pages/gameover/gameover.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { EndGameComponent } from './pages/end-game/end-game.component';
import { EndGameGiftPageComponent } from './pages/end-game-gift-page/end-game-gift-page.component'
import { DidascalieComponent } from './pages/didascalie/didascalie.component';
import { Gameover2Component } from './pages/gameover2/gameover2.component';
import { TutoComponent } from './pages/tuto/tuto.component';
import { MesureImpactComponent } from './pages/mesureImpact/mesure-impact.component';



export const routes: Routes = [
	{ path: '', redirectTo: 'landing', pathMatch: 'full' },

	{ path: 'landing', component: LandingComponent },
	{ path: 'home', component: HomeComponent },
	{ path: 'login', component: CreateAccountComponent },
	{ path: 'game', component: PageGameComponent },
	{ path: 'chapter-start', component: ChapterStartComponent },
	{ path: 'chapter-end', component: ChapterEndComponent },
	{ path: 'gameover2', component: Gameover2Component },
	{ path: 'quiz', component: QuizComponent },
	{ path: 'gameover', component: GameoverComponent },
	{ path: 'game-end', component: EndGameComponent },
	{ path: 'game-end-gift-page', component: EndGameGiftPageComponent },
	{ path: 'mesureImpact', component: MesureImpactComponent },


	{ path: 'history', outlet: 'modal_game', component: ModalHistoryComponent },

	{ path: 'menu', outlet: 'modal_menu', component: MenuComponent },

	{ path: 'confirmquit', outlet: 'modal', component: ConfirmquitComponent },
	{ path: 'leaderboard', outlet: 'modal', component: LeaderboardComponent },
	{ path: 'credits', outlet: 'modal', component: CreditsComponent },
	{ path: 'elipse', outlet: 'modal', component: DidascalieComponent },
	{ path: 'tuto', outlet: 'modal', component: TutoComponent },

];
