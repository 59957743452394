import { Component, OnInit, HostBinding } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { chapterStartAnimation } from 'src/app/animations/chapter-start.animations';
import { UserDataService } from 'src/app/services/user-data.service';
import { SoundPlayerService } from 'src/app/shared/sound/sound-player.service';
import { environment } from 'src/environments/environment';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
	selector: 'app-chapter-start',
	templateUrl: './chapter-start.component.html',
	styleUrls: ['./chapter-start.component.scss'],
	animations: [chapterStartAnimation]
})
export class ChapterStartComponent implements OnInit {

	@HostBinding('@animations') private animations;

	data: {
		title: string,
		name: string,
		desc: string,
		img: string,

		title1: string,
		desc1: string,
		title2: string,
		desc2: string,
	};

	styleBG: any;
	chapterStarted: boolean;


	constructor(
		private ds: DataService,
		private modalsService: ModalsService,
		private userData: UserDataService,
		private sound: SoundPlayerService,
		private wordings: WordingsService,
	) { }

	ngOnInit() {

		let indexScenario: number = this.userData.data.indexScenario;

		setTimeout(() => {
			this.sound.play('assets/sounds/cobraz_jaccepte_woosh.mp3');

		}, 920);


		if (indexScenario >= 4) {
			this.userData.data.indexScenario = 0;
			this.userData.data.indexProgression = 0;
			indexScenario = 0;
			this.userData.resetChapter();
		}

		this.data = {
			title: this.wordings.translate('ui.chap' + indexScenario + '.title'),
			name: this.wordings.translate('ui.chap' + indexScenario + '.name'),
			desc: this.wordings.translate('ui.chap' + indexScenario + '.desc'),
			img: 'assets/dynamic/decors/bg_chapter_' + indexScenario + '.png',

			title1: this.wordings.translate('ui.chap' + indexScenario + '.jauge1.title'),
			desc1: this.wordings.translate('ui.chap' + indexScenario + '.jauge1.text'),
			title2: this.wordings.translate('ui.chap' + indexScenario + '.jauge2.title'),
			desc2: this.wordings.translate('ui.chap' + indexScenario + '.jauge2.text'),

		};

		this.styleBG = {
			'background-image': 'url("' + this.data.img + '")',
		};


		let nodeId: string = this.userData.data.nodeId;
		this.chapterStarted = (nodeId != null && nodeId != '');
		// if(environment.debug) this.chapterStarted = true;



		let is: number = this.userData.data.indexScenario;
		if (is == 1) this.userData.data.indexProgression = 1;
		else if (is == 2) this.userData.data.indexProgression = 3;
		else if (is == 3) this.userData.data.indexProgression = 5;
		else if (is == 4) this.userData.data.indexProgression = 7;

		this.userData.save();
	}

	clickStart(): void {
		this.modalsService.open('game');
	}

	clickResume(): void {
		this.modalsService.open('game');
	}

	clickRestart(): void {
		this.userData.resetChapter();
		this.userData.save();
		this.modalsService.open('game');
	}
}
