import { routes } from './app.routes';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { PageGameComponent } from './pages/page-game/page-game.component';
import { LandingComponent } from './pages/landing/landing.component';
import { HomeComponent } from './pages/home/home.component';
import { ChapterStartComponent } from './pages/chapter-start/chapter-start.component';
import { MenuComponent } from './pages/menu/menu.component';
import { ConfirmquitComponent } from './pages/confirmquit/confirmquit.component';
import { ModalHistoryComponent } from './modals/modal-history/modal-history.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { ChapterEndComponent } from './pages/chapter-end/chapter-end.component';
import { CreditsComponent } from './pages/credits/credits.component';
import { GameoverComponent } from './pages/gameover/gameover.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { EndGameComponent } from './pages/end-game/end-game.component';
import { EndGameGiftPageComponent } from './pages/end-game-gift-page/end-game-gift-page.component';
import { JaugeComponent } from './components/jauge/jauge.component';
import { DidascalieComponent } from './pages/didascalie/didascalie.component';
import { Gameover2Component } from './pages/gameover2/gameover2.component';
import { SoundClickDirective } from './shared/sound/sound-click.directive';
import { HiddenDirective } from './shared/directives/hidden.directive';
import { VisibleDirective } from './shared/directives/visible.directive';
import { TutoComponent } from './pages/tuto/tuto.component';
import { MesureImpactComponent } from './pages/mesureImpact/mesure-impact.component';
import { MesureImpactStateService } from './services/mesure-impact-state.service';
import { FormLineComponent } from './pages/form-line/form-line.component';
import { CryptoJS } from "node_modules/crypto-js/crypto-js.js";
import { LanguageSelectComponent } from './pages/language-select/language-select.component';
import { QuizComponent } from './pages/quiz/quiz.component';

@NgModule({
  declarations: [
    AppComponent,
    PageGameComponent,
    LandingComponent,
    HomeComponent,
    ChapterStartComponent,
    MenuComponent,
    ConfirmquitComponent,
    ModalHistoryComponent,
    QuizComponent,
    CreateAccountComponent,
    ChapterEndComponent,
    CreditsComponent,
    GameoverComponent,
    FormLineComponent,
    LeaderboardComponent,
    EndGameComponent,
    EndGameGiftPageComponent,
    JaugeComponent,
    DidascalieComponent,
    Gameover2Component,
    SoundClickDirective,
    HiddenDirective,
    VisibleDirective,
    TutoComponent,
    MesureImpactComponent,
    LanguageSelectComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule.forRoot(
      [
        { target: '\n', replace: '<br />' },
      ]
    ),
    RouterModule.forRoot(routes, {
      useHash: true,
      // initialNavigation: false,
      // enableTracing: true,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    HttpModule,
  ],
  providers: [MesureImpactStateService],
  bootstrap: [AppComponent]
})
export class AppModule { }
