import { environment } from '../../../environments/environment';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { Component, OnInit, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { quizAnimation, quizSubAnimation } from 'src/app/animations/quiz.animations';
import { QuizItem } from 'src/app/model/quizitem.interface';
import { DataService, QuizLocalizedItem } from 'src/app/services/data.service';
import {TweenMax, Elastic} from "gsap";
import { UserDataService } from 'src/app/services/user-data.service';
import { MathService } from 'src/app/shared/math/math.service';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { SoundPlayerService } from 'src/app/shared/sound/sound-player.service';
import { Easing } from 'src/app/shared/animation/animation.interface';
import { GoogleAnalyticsService } from 'src/app/shared/api/google-analytics.service';
import { BroadcasterService } from 'src/app/shared/events/broadcaster.service';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
	styleUrls: ['./quiz.component.scss'],
	animations: [quizAnimation, quizSubAnimation],
})
export class QuizComponent implements OnInit {
	
	@ViewChild('question') questionElmt:ElementRef;
	@ViewChild('answers') answersElmt:ElementRef;
	
	@HostBinding('@animations') private animations;
	constructor(
		private ds:DataService,
		private modalService:ModalsService,
		private userData:UserDataService,
		private math:MathService,
		private animate:TweenAnimateService,
		private soundPlayer:SoundPlayerService,
		private ga:GoogleAnalyticsService,
		private routerEvent:RouterEventService,
		private wordings: WordingsService,
	) { }
	
	public item:QuizItem;
	index:number;
	selectedAnswer:number;
	score:number;
	scoreDisplay:number;
	pts_won:number;
	submitted:boolean;
	showFlash:boolean;
	styleTimerFilling:any;
	styleTimerTip:any;
	timerProgress:number;
	interval;
	displayTuto:boolean;
	nbquestions:number;
	flashColor:string;
	
	
  ngOnInit() {
		
		this.index = (environment.debug) ? 0 : 0;
		this.score = this.userData.data.currentScore;
		this.scoreDisplay = this.score;
		this.showFlash = false;
		
		this.displayTuto = true;
		this.animations = false;
		this.nbquestions = this.ds.quiz.length;
		
		this.routerEvent.addListener(['quiz'], 
		() => {},
		() => {
			clearInterval(this.interval);
			this.soundPlayer.pause('assets/sounds/cobraz_quiz_time.mp3');
		});
		
		this.nbquestions = this.ds.quiz ? this.ds.quiz.length : 0;
		// this.flashColor = 'purple';
		//#c21c37 (red)
		//#7cce33 (green)
	}
	
	
	clickValidTuto():void
	{
		this.displayTuto = false;
		
		this.animations = true;
		this.update();
	}
	
	
	setTimerProgress(progress:number):void
	{
		let width:number = 536 * progress;
		this.styleTimerFilling = {
			width : width+'px',
			left : 8 + (536 - width) + 'px',
		};
		this.styleTimerTip = {
			left : 7 + (536 - width) + 'px',
		};
	}
	
	
	clickAnswer(index:number):void
	{
		this.selectedAnswer = index;
	}

	valid():void
	{
		// this.index = this.ds.quiz.length; // DEBUG
		
		this.animate.reset();
		
		if(this.selectedAnswer != -1){
			
			this.ga.emitEvent('quiz', 'reponse', 'q'+(this.index+1)+'_a'+(this.selectedAnswer));
			
			this.submitted = true;
			let success:boolean = (this.selectedAnswer+'') == this.item.solution;
			// if(environment.debug) success = true;
			
			if(success){
				
				let p:any = this.ds.params.score.quiz;
				let time:number = (1 - this.timerProgress) * p.time;
				time = Math.floor(time);
				let nbpts:number = this.math.getProgressionValue(time, p.time_min, p.time_max, p.pts_max, p.pts_min);
				nbpts = Math.round(nbpts);
				
				this.userData.data.currentScore += nbpts;
				this.pts_won = nbpts;
				this.score = this.userData.data.currentScore;
				
				
				this.flashColor = "#7cce33";
				this.animate.flash('.flash', 0, { time: 0.3 });
				
				this.soundPlayer.play('assets/sounds/cobraz_point_gain.mp3', { volume: 1.0 });
				
				this.animate.tweenValue(this, 0, {
					prop: 'scoreDisplay',
					endValue: this.score,
					time:0.6,
					round:true,
				});
				
				this.animate.zoomIn('.pts_won_value', 0, {
					timeIn: 0.15,
					easeIn: Easing.NONE,
					fade: false,
				});
				
				this.animate.animBonus('.pts_won_value', 0.07, {
					time:0.7,
					useMargin: true,
					startValue: 55,
					endValue: 10,
				});
				
			}
			else{
				this.flashColor = "#c21c37";
				this.animate.flash('.flash', 0, { time: 0.3 });
				this.soundPlayer.play('assets/sounds/cobraz_quiz_fail.mp3', { volume: 1.0 });
			}
			
			this.soundPlayer.pause('assets/sounds/cobraz_quiz_time.mp3');
			clearInterval(this.interval);
			
			
			
			//todo feedback
			//...
			/* 
			TweenMax.to(this.questionElmt.nativeElement, 0.3, {opacity:0, delay:1.0});
			TweenMax.to(this.questionElmt.nativeElement, 0.3, {opacity:0, delay:1.3});
			 */
			this.animations = false;
			
			if(!environment.debug || true){
				setTimeout(() => {
					
					this.index++;
					this.update();
					/* 
					TweenMax.to(this.questionElmt.nativeElement, 0.3, {opacity:0, delay:1.0});
					TweenMax.to(this.questionElmt.nativeElement, 0.3, {opacity:0, delay:1.3});
					*/
					this.animations = true;
				}, 1000);
			}
			
			
		}
	}
	
	
	
	update():void
	{
		//end
		if(this.index >= this.ds.quiz.length){
			
			clearInterval(this.interval);
			
			this.modalService.open('gameover');
			return;
		}
		
		this.submitted = false;
		this.selectedAnswer = -1;
		this.item = this.ds.quiz[this.index][this.wordings.selectedLanguage];
		
		this.ga.emitEvent('quiz', 'question', 'q'+(this.index+1));
		
		this.timerProgress = 1.0;
		this.setTimerProgress(this.timerProgress);
		
		
		let timeout:number = (this.index == 0) ? 2000 : 800;
		clearInterval(this.interval);
		let decrement:number = (1/30) / this.ds.params.score.quiz.time;
		
		setTimeout(() => {
			this.soundPlayer.play('assets/sounds/cobraz_quiz_time.mp3', {
				loop: true,
				volume: 0.5,
			});
			
			
			this.interval = setInterval(() => {
				
				// console.log('interval');
				this.timerProgress -= decrement;
				this.setTimerProgress(this.timerProgress);
				if(this.timerProgress <= 0){
					
					this.timeout();
				}
				
			}, 1000 / 30);
		}, timeout);
		
	}
	  
	
	timeout():void
	{
		this.soundPlayer.pause('assets/sounds/cobraz_quiz_time.mp3');
		clearInterval(this.interval);
		this.flashColor = "#c21c37";
		this.animate.flash('.flash', 0, { time: 0.3 });
		this.animations = false;
		console.log('timeout');
		
		setTimeout(() => {
			
			this.index++;
			this.animations = true;
			this.update();
			
		}, 1000);
		
	}
	
	
	
	
}
