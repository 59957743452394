import { NavigationService } from './../../services/navigation.service';
import { Component, OnInit, HostBinding } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { createAccountAnimation } from 'src/app/animations/create-account.animations';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
	selector: 'app-create-account',
	templateUrl: './create-account.component.html',
	styleUrls: ['./create-account.component.scss'],
	animations: [createAccountAnimation],
})
export class CreateAccountComponent implements OnInit {

	@HostBinding('@animations') private animations;

	email: string;
	pwd: string;
	errorMessage: string;

	constructor(
		private ds: DataService,
		private modalsService: ModalsService,
		private navigation: NavigationService,
		private api: ApiService,
		private wordings: WordingsService,
	) { }

	ngOnInit() {
		if (environment.debug) {
			this.email = 'test';
			this.pwd = 'pwd';
		}
	}

	valid(): void {
		if ((this.email && this.pwd) || environment.debug) {
			this.api.login(this.email, this.pwd).toPromise()
				.then((resp) => {
					if (resp.success || environment.debug) {
						this.navigation.startGame();
						this.errorMessage = '';
					}
					else {
						this.errorMessage = resp.data.errorMessage;
					}
				});
		}
	}

}
