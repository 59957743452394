import { query, transition, trigger, group, useAnimation, animate, animation, animateChild, style, stagger, sequence } from '@angular/animations';
import { dsl } from './app.animations';



/*
decorator :
animations: [xAnimations],
declarations :
@HostBinding('@animations') private animations;

composition :
query('.dsl_x', group([
	dsl.zoomIn(0, {scaleStart: 2}),
])),
*/

export const ellipseAnimation = [
	
  trigger('animations', [
		
    transition(':enter', group([
			 
			query('.dsl_bg', dsl.reset().fadeIn(0.0)),
			query('.dsl_fadeout', dsl.fadeIn(0.7)),
			
		])),
		
		transition(':leave', group([
			
			query('.dsl_fadeout', dsl.reset().fadeOut()),
			query('.dsl_bg', dsl.fadeOut(0.3)),
			
			
		])),
		
  ])
];

