import { query, transition, trigger, group, useAnimation, animate, animation, animateChild, style, stagger, sequence } from '@angular/animations';
import { dsl } from './app.animations';



/*
decorator :
animations: [xAnimations],
declarations :
@HostBinding('@animations') private animations;

composition :
query('.dsl_x', group([
	dsl.zoomIn(0, {scaleStart: 2}),
])),
*/

export const chapterendAnimation = [
	
  trigger('animations', [
		
    transition(':enter', group([
			 
			query('.dsl_fade1', dsl.fadeIn(0.8)),
			
			query('.dsl_zoomx', dsl.zoomIn(0.2, {zoomX:true})),
			query('.dsl_fade2', dsl.fadeIn(0.2), {optional: true}),
			
			query('.dsl_left', dsl.slideIn(0.4, {dir:'left', distanceIn:120})),
			query('.dsl_right', dsl.slideIn(0.0, {dir:'right', distanceIn:120})),
			
			query('.dsl_top1', dsl.slideIn(0.3, {dir:'top', distanceIn:40})),
			query('.dsl_top2', dsl.slideIn(0.15, {dir:'top', distanceIn:40})),
			
			query('.dsl_up', dsl.slideIn(3.3, {dir:'top', distanceIn:40, fade:true})),
			query('.dsl_top3', dsl.slideIn(0.15, {dir:'top', distanceIn:40})),
			
			
			// query('.dsl_top3', dsl.slideIn(0.15, {dir:'top', distanceIn:40})),
			
			// ...dsl.sequence(['.dsl_fade1', '.dsl_fade2', '.dsl_fade3'], dsl.reset().fadeIn, 0.5)
			
		])),
		
		transition(':leave', group([
			
			query('.dsl_fadeout', dsl.reset().fadeOut()),
			
		])),
		
  ])
];

