import { Component, OnInit, HostBinding, ViewChild, ElementRef, ApplicationRef, NgZone } from '@angular/core';
import { creditsAnimation } from 'src/app/animations/credits.animations';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { InfoScrollService } from 'src/app/shared/ui/info-scroll.service';
import * as $ from 'jquery';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { GoogleAnalyticsService } from 'src/app/shared/api/google-analytics.service';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { Easing } from 'src/app/shared/animation/animation.interface';
import { WordingsService } from 'src/app/services/wordings.service';

declare var tarteaucitron: any;

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
	styleUrls: ['./credits.component.scss'],
	animations: [creditsAnimation],
})
export class CreditsComponent implements OnInit {
	
	@HostBinding('@animations') private animations;
	@ViewChild('descElmt') descElmt:ElementRef;
	
	title:string;
	desc:string;
	collect:string;
	tracking:boolean;

	scrollValue: number;
	
	infoScroll:InfoScrollService;

  constructor(
		private ds:DataService,
		private app:ApplicationRef,
		private storage:StorageService,
		private ga:GoogleAnalyticsService,
		private animate: TweenAnimateService,
		private ngZone: NgZone,
		private wordings: WordingsService,
	) { }

  ngOnInit() {
		
		
		
		if(!this.ds.typeCredit) this.ds.typeCredit = 'credits';
		
		this.title = this.wordings.translate(this.ds.typeCredit + '.title');
		this.desc = this.wordings.translate(this.ds.typeCredit + '.text');
		/* 
		if(environment.debug){
			this.desc += this.desc; this.desc += this.desc; this.desc += this.desc;
			this.desc += this.desc; this.desc += this.desc; this.desc += this.desc;
			this.desc += this.desc;
		}
		 */
		this.collect = this.wordings.translate('disclaimer.tracking.cookies');
		
		
		this.infoScroll = new InfoScrollService();
		this.infoScroll.init(this.descElmt.nativeElement);
		
		setTimeout(() => {	
			this.infoScroll.update();
		}, 99);
		
		
		this.tracking = this.storage.getBoolean('tracking');
		
		
	}
	
	
	public scroll(delta:number, distance:number):void
	{
		let elmt = this.descElmt.nativeElement;
		this.animate.tweenValue(this, 0, {
			time: 0.23,
			prop: 'jaugeA',
			ease: Easing.NONE, 
			startValue: elmt.scrollTop,
			endValue: elmt.scrollTop + delta * distance,
			onUpdate: () => this.ngZone.run(() => {elmt.scrollTop = this.scrollValue;}),
			round:true,
		});
	}
	
	
	private onChangeTracking(event):void
	{
		this.tracking;
		this.storage.setBoolean('tracking', this.tracking);
		this.ga.enabled = (!this.tracking);
	}

	private openTrackingPage():void
	{
		tarteaucitron.userInterface.openPanel();
	}

}
