import { Component, OnInit, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { environment } from 'src/environments/environment';
import { landingAnimation } from 'src/app/animations/landing.animations';
import { TimeoutService } from 'src/app/shared/time/timeout.service';
import { fxAnimations } from 'src/app/animations/fx.animations';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss'],
	animations: [landingAnimation, fxAnimations],
})
export class LandingComponent implements OnInit {
	
	@HostBinding('@animations') private animations;
	@ViewChild('image') imgRef:ElementRef;
	
	prefix:string;

  constructor(
		private ds:DataService,
		private modalService:ModalsService,
		private timeout:TimeoutService,
		private wordings: WordingsService,
	) { }
	
	
  ngOnInit() {
		
		// this.imgRef.nativeElement.onload = this.onImgLoad.bind(this);
		this.prefix = environment.dataUrl;
		
		if(!environment.debug || true){
			this.timeout.setTimeout(function() {
				this.modalService.open('home');
			}.bind(this), 3000);
		}
		
	}
	/* 
	onImgLoad():void
	{
		
		if(!environment.debug || true){
			this.timeout.setTimeout(function() {
				this.modalService.open('home');
			}.bind(this), 2500 + 1000);
		}
	}
	 */

}
