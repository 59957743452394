import { UserDataService } from 'src/app/services/user-data.service';
import { NavigationService } from './../../services/navigation.service';
import { Component, OnInit, HostBinding, ApplicationRef } from '@angular/core';
import { chapterendAnimation } from 'src/app/animations/chapterend.animations';
import { DataService } from 'src/app/services/data.service';
import {TweenMax, Linear} from "gsap";
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { TimeoutService } from 'src/app/shared/time/timeout.service';
import { environment } from 'src/environments/environment';
import { NavigationService as NavigationServiceShared} from './../../shared/navigation/navigation.service';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { Easing } from 'src/app/shared/animation/animation.interface';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-gameover2',
  templateUrl: './gameover2.component.html',
  styleUrls: ['./gameover2.component.scss'],
	animations: [chapterendAnimation],
})
export class Gameover2Component implements OnInit {
	
	@HostBinding('@animations') private animations;
	
	
	
	keys:string[]= [
		'secret',
		'etiquepro',
		'pedagogie',
		'persuasion',
		'credibilite',
		'bienveillance',
	];
	
	data:any;
	
	label_jauge1:string;
	label_jauge2:string;
	desc:string;
	
	colorTest:any;
	contentTest:any;
	

  constructor(
		public ds:DataService,
		private modalService:ModalsService,
		private timeout:TimeoutService,
		private navigation:NavigationService,
		private userData:UserDataService,
		private navShared:NavigationServiceShared,
		private animate:TweenAnimateService,
		private app:ApplicationRef,
		private wordings: WordingsService,
	) { }

  ngOnInit() {
		
		let indexScenario = this.userData.data.indexScenario;
		
		
		
		let params:any = this.navShared.getParams('gameover2');
		if(!params){
			params = {
				jaugeA_start: 59,
				jaugeA_end: 29,
				jaugeB_start: 39,
				jaugeB_end: 0,
			};
		}
		
		
		let indexLost:number = (params.jaugeA_end <= 0) ? 0 : 1;
		let indexSentenceLost:number = (indexScenario - 1) * 2 + indexLost;
		this.desc = 'ui.gameover.' + this.keys[indexSentenceLost];
		
		
		this.data = {
			jaugeA: params.jaugeA_start,
			jaugeB: params.jaugeB_start,
			score: this.userData.data.currentScore + this.ds.scoreChapter || 962,
			textColorA: '#ffffff',
			textColorB: '#ffffff',
		};
		
		this.animate.reset();
		this.animate.tweenValue(this.data, 2.8, {
			time: 1.0,
			prop: 'jaugeA',
			ease: Easing.NONE, 
			startValue: params.jaugeA_start,
			endValue: params.jaugeA_end,
			onUpdate: () => this.app.tick(),
			round:true,
		});
		
		this.animate.tweenValue(this.data, 0, {
			time: 1.0,
			prop: 'jaugeB',
			ease: Easing.NONE, 
			startValue: params.jaugeB_start,
			endValue: params.jaugeB_end,
			onUpdate: () => this.app.tick(),
			round:true,
		});
		
		this.animate.tweenValue(this.data, 0.2, {
			time: 1.2,
			prop: 'score',
			ease: Easing.NONE, 
			endValue: this.userData.data.currentScore,
			onUpdate: () => this.app.tick(),
			round:true,
		});
		
		
		//change text to red
		let prop:string = params.jaugeA_end <= 0 ? 'textColorA' : 'textColorB';
		
		this.animate.tweenValue(this.data, 0, {
			time: 1.0,
			prop: prop,
			ease: Easing.NONE, 
			startValue: '#ffffff',
			endValue: '#c21c37',
			onUpdate: () => this.app.tick(),
		});
		
		
		/* 
		setTimeout(() => {
			
			if(!environment.debug) this.navigation.afterChapterEnd(false);
			
		}, 4000);
		 */
		
		
		 
		 
		
		this.label_jauge1 = this.wordings.translate('ui.game.jauge' + (indexScenario*2-1));
		this.label_jauge2 = this.wordings.translate('ui.game.jauge' + (indexScenario*2-0));
		
		
		this.userData.data.ngGameover++;
		this.userData.resetChapter();
		this.userData.save();
		
	}
	
	
	clickRestart():void
	{
		this.modalService.open('chapter-start');
	}

}
