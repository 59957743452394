import { Injectable } from '@angular/core';
import { TweenAnimateService } from '../shared/animation/tween.animate.service';
import { Easing } from '../shared/animation/animation.interface';

@Injectable({
  providedIn: 'root'
})
export class AppAnimateService {

	constructor(
		private animate:TweenAnimateService,
	) { }
	
	
	public animateJaugeHeader(selector:string):void
	{
		this.animate.reset();
		
		this.animate.blink(selector, 0, {
			blinkTimes: 3,
			blinkDelay: 0.25,
		});
		
		
		this.animate.zoomIn(selector, 0.8, {
			timeIn: 0.15,
			scaleStart: 1,
			scaleEnd: 1.1,
			easeIn: Easing.EASE_OUT,
			fade: false,
		});
		
		this.animate.zoomIn(selector, 1.1, {
			timeIn: 0.15,
			scaleStart: 1.1,
			scaleEnd: 1,
			easeIn: Easing.EASE_OUT,
			fade: false,
		});
	}
	
}
