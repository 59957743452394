import { Component, OnInit, HostBinding } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { leaderboardAnimation } from 'src/app/animations/leaderboard.animations';
import { DataService } from 'src/app/services/data.service';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
	styleUrls: ['./leaderboard.component.scss'],
	animations: [leaderboardAnimation],
})
export class LeaderboardComponent implements OnInit {

	@HostBinding('@animations') private animations;
	
	gameover:boolean;
	
  constructor(
		private modalService:ModalsService,
		private routerEvent:RouterEventService,
		public ds:DataService,
		private wordings: WordingsService,
	) { }

  ngOnInit() {
		this.gameover = this.routerEvent.isRouteActive('gameover');
  	}

	valid():void
	{
		//from gameover
		if(this.routerEvent.isRouteActive('gameover')){
			this.modalService.close('modal')
			.then(() => {
				this.modalService.open('game-end');
			});
		}
		//from menu
		else this.modalService.close('modal');
	}
	
	
}
