import { Component, OnInit, HostBinding, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { gameoverAnimation } from 'src/app/animations/home.animations';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { environment } from 'src/environments/environment';
import { MesureImpactStateService } from "src/app/services/mesure-impact-state.service";
import { WordingsService } from 'src/app/services/wordings.service';
declare var dmi: any;
declare var pipwerks;

type FormFieldData = ComboFormData | TextFormData;

export class FormElementDescriptor {
	public displayName: any;
	public configName: string;
	public type: string;
	public options: string[];
}

export class ComboFormData {
	public displayName: any;
	public configName: string;
	public type: string = "combo";
	public options: (string[] | Object);
	
}

export class TextFormData {
	public displayName: any;
	public configName: string;
	public type: string = "text";
	public placeholderText: string;
}

// Handles rendering of a customizable form line for the leaderboard
@Component({
	selector: 'app-form-line',
	templateUrl: './form-line.component.html',
	styleUrls: ['./form-line.component.scss'],
	animations: [gameoverAnimation],
})
export class FormLineComponent implements OnInit {
	@Input() config: FormFieldData[];
	@Input() selectedOptions: string[] = [];
	@Input() index: number;
	@Output() valueChanged: EventEmitter<any> = new EventEmitter();

	constructor(
		private modalService: ModalsService,
		private api: ApiService,
		public ds: DataService,
		private userData: UserDataService,
		private impactService: MesureImpactStateService,
		private wordings: WordingsService, )
	{

	}

	ngOnInit() {
	}

	onValueChanged(value: string, elementIndex: number) {
		this.valueChanged.emit({lineIndex: this.index, elementIndex: elementIndex, value: value});
	}
	test(value: any)
	{
	}
}
