import { Component, OnInit, HostBinding } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { tutoAnimation } from 'src/app/animations/tuto.animations';
import { DataService } from 'src/app/services/data.service';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-tuto',
  templateUrl: './tuto.component.html',
  styleUrls: ['./tuto.component.scss'],
	animations: [tutoAnimation],
})
export class TutoComponent implements OnInit {
	
	@HostBinding('@animations') private animations;
	index:number;
	isNext:boolean;
	label_valid5:string;
	displayLogoBG:boolean;

  constructor(
		private appNavigation:NavigationService,
		private ds:DataService,
		private routerEvent:RouterEventService,
		private wordings: WordingsService,
	) { }

  ngOnInit() {
		this.index = 0;
		this.isNext = true;
		
		
		this.label_valid5 = this.wordings.translate('ui.tuto5.launch');
		if(this.ds.opentutoMenu) this.label_valid5 = this.wordings.translate('ui.tuto5.menu.launch');
		this.displayLogoBG = this.routerEvent.isRouteActive('game');
	}
	
	
	valid():void
	{
		if(this.index < 4) this.index++;
		else this.appNavigation.afterTuto();
	}

}
