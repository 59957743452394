import { Component, OnInit, HostBinding } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { endAnimation } from 'src/app/animations/home.animations';
import { DataService } from 'src/app/services/data.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { WordingsService } from 'src/app/services/wordings.service';
declare var dmi: any;

@Component({
  selector: 'app-end-game',
  templateUrl: './end-game.component.html',
	styleUrls: ['./end-game.component.scss'],
	animations: [endAnimation],
})
export class EndGameComponent implements OnInit {

	@HostBinding('@animations') private animations;
  constructor(
		private modalService:ModalsService,
		private ds:DataService,
		private userData:UserDataService,
		private wordings: WordingsService,
	) { }

  ngOnInit() {
	}
	restart():void
	{
		this.userData.resetAll();
		this.userData.save();
		this.modalService.open('home');
	}
	
	open_mi(): void
	{
			this.modalService.open("mesureImpact");
	}

	show_mi(): boolean
	{
		return this.ds.versionAllowedForm && !dmi.userIsFinished();
	}
	
	trim_double_br(text: string): string
	{
		return text.replace("\n", "");
	}
}
